<div class="flex f-fd--column">
  <div>
    <howden-page-title></howden-page-title>
  </div>

  <form [formGroup]="model.filterForm">
    <div class="flex f-fd--column-sm f-gap--10px">
      <mat-form-field class="f-basis--2">
        <mat-label i18n="@@app.user-data.search.email">Email</mat-label>
        <input matInput [formControlName]="model.C_USER_EMAIL" [howdenControlError]="userEmailError" />
        <mat-error #userEmailError></mat-error>
      </mat-form-field>

      <mat-form-field class="f-basis--2">
        <mat-label i18n="@@app.user-data.edit.userRole">Rol</mat-label>
        <mat-select [formControlName]=" model.C_ROLE_NAME" [howdenControlError]="userRoleError" #roleSelected>
          <mat-option></mat-option>
          @for (item of model.roles | howdenArraySort : 'description'; track item.name) {
            <mat-option [value]="item.name">
              {{ item.description }}
            </mat-option>
          }
        </mat-select>
        <mat-error #userRoleError></mat-error>
      </mat-form-field>

      <mat-form-field class="f-basis--2">
        <mat-label i18n="@@app.user-data.search.fiscalCode">ID Fiscal</mat-label>
        <input matInput [formControlName]="model.C_FISCAL_CODE" />
      </mat-form-field>

      <mat-form-field class="f-basis--2">
        <mat-label i18n="@@app.user-data.search.bussinessName">Razón social</mat-label>
        <input matInput [formControlName]="model.C_BUSINESS_NAME" />
      </mat-form-field>

      <mat-form-field class="f-basis--2">
        <mat-label i18n="@@app.user-request.search.recruitmentPlanId">Plan Contratado</mat-label>
        <mat-select [formControlName]="model.C_RECRUITMENT_PLAN" [howdenControlError]="recruitmentPlanIdError">
          <mat-option></mat-option>
          @for (item of model.recruitmentPlans | howdenArraySort : 'description' ; track item.id) {
            <mat-option [value]="item.id">
              {{ item.description }}
            </mat-option>
          }
        </mat-select>
        <mat-error #recruitmentPlanIdError></mat-error>
      </mat-form-field>

      <mat-form-field class="f-basis--2">
        <mat-label i18n="@@app.user-data.search.status">Estado</mat-label>
        <mat-select [formControlName]="model.C_STATUS" [howdenControlError]="statusValuesError">
          <mat-option></mat-option>
          @for (item of model.statusValues | howdenArraySort : 'value'; track item.key) {
            <mat-option [value]="item.key">
              {{ item.value }}
            </mat-option>
          }
        </mat-select>
        <mat-error #statusValuesError></mat-error>
      </mat-form-field>
    </div>
  </form>

  <div>
    <howden-table
      class="small-font"
      keyField="id"
      paginable="server"
      sortable="server"
      filterable="server"
      [clickableRow]="true"
      [cols]="model.columns"
      [data]="model.data"
      [currentPage]="model.searchRequest.pageNumber"
      [pageSize]="model.searchRequest.pageSize"
      [totalRows]="model.length"
      [buttons]="model.buttons"
      (rowClicked)="onRowClicked($event)"
      (pageChanged)="onServerSideConfigChanged($event)"
      (sortChanged)="onServerSideConfigChanged($event)"
      >
    </howden-table>
  </div>
</div>
