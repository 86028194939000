import { Injectable } from '@angular/core';
import { HowdenHttpClientService } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { API_PATH_APPS } from 'src/app/core/constants';
import { IComment } from 'src/app/core/models/api/master/comment';

import { CommentContract } from './comment.contract';

@Injectable({
  providedIn: 'root'
})
export class CommentService implements CommentContract {
  apiName = API_PATH_APPS.API_INFORMATION_SERVICES;
  basePath = 'api/comment';

  constructor(private httpClient: HowdenHttpClientService) { }

  getComments(type: number): Observable<Array<IComment>> {
    return this.httpClient.get<Array<IComment>>(
      this.apiName,
      `${this.basePath}/${type}`
    );
  }
}
