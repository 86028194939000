<div class="flex f-fd--column">
  <div>
    <howden-page-title></howden-page-title>
  </div>

  <form [formGroup]="model.filterForm">
    <div class="flex f-gap--10px margin-bottom-10">
      @if (!isClientProfile) {
        <div [ngClass]="{'f-basis--3': !isClientProfile}">
          <howden-table
            class="small-font"
            keyField="userId"
            paginable="local"
            sortable="local"
            filterable="global-local"
            [pageSize]="10"
            [clickableRow]="true"
            [highlightRow]="true"
            [cols]="model.columnsClients"
            [data]="model.dataClients"
            (rowClicked)="onUserSelectChange($event)"
          >
          </howden-table>
        </div>
      }

      <div [ngClass]="{'f-basis--12': isClientProfile, 'f-basis--9': !isClientProfile}" >
        <div class="flex f-gap--10px">
          <mat-form-field class="f-basis--2">
            <mat-label i18n="@@app.debtor.search.fiscalCode">ID Fiscal</mat-label>
            <input  matInput
              [formControlName]="model.C_FISCAL_CODE"
              [howdenControlError]="fiscalCodeCodeError"
              (input)="onFilterChanges()"
              maxlength="20"
            />
            <mat-error #fiscalCodeCodeError></mat-error>
          </mat-form-field>

          <mat-form-field class="f-basis--2">
            <mat-label i18n="@@app.debtor.search.businessName">Razón social</mat-label>
            <input matInput
              [formControlName]="model.C_BUSINESS_NAME"
              [howdenControlError]="businessNameError"
              (input)="onFilterChanges()"
              maxlength="60"
            />
            <mat-error #businessNameError></mat-error>
          </mat-form-field>

          <mat-form-field class="f-basis--2">
            <mat-label i18n="@@app.debtor.search.ownIdentifier">Identificador interno</mat-label>
            <input
              matInput
              [formControlName]="model.C_OWN_IDENTIFIER"
              [howdenControlError]="ownIdentifierError"
              (input)="onFilterChanges()"
              maxlength="50"
            />
            <mat-error #ownIdentifierError></mat-error>
          </mat-form-field>

          <mat-slide-toggle
            color="primary"
            class="f-basis--2 margin-top-20"
            [formControlName]="model.C_NOT_MONITORABLE"
            (change)="onFilterChanges()"
            i18n="@@app.debtor.search.notMonitorable"
          >
            Sin seguimiento
          </mat-slide-toggle>
          @if (canAddDebtor) {
            <div class="f-basis--2 margin-top-20 margin-left-15">
              <button class="new-button" type="button" mat-raised-button  color="primary" (click)="onNew()">
                <span i18n="@@app.debtor.search.new-debtor">Nuevo deudor</span>
              </button>
            </div>
          }
          <div class="f-basis--2 margin-top-20 margin-right-25">
            @if (model.counterMsg) {
              <howden-label-value class="counter-text"
                value="{{model.counterInfo}}"
                title="{{model.counterMsg}}"
              ></howden-label-value>
            }
          </div>
        </div>

        <howden-table
          class="small-font"
          keyField="id"
          paginable="server"
          sortable="server"
          filterable="server"
          [clickableRow]="true"
          [cols]="model.columns"
          [data]="model.data"
          [currentPage]="model.searchRequest.pageNumber"
          [pageSize]="model.searchRequest.pageSize"
          [totalRows]="model.length"
          (rowClicked)="onRowClicked($event)"
          (pageChanged)="onServerSideConfigChanged($event)"
          (sortChanged)="onServerSideConfigChanged($event)"
        >
        </howden-table>
      </div>
    </div>
  </form>
</div>
