import { Component } from '@angular/core';

@Component({
  selector: 'howden-report-issues',
  templateUrl: './report-issues.component.html',
  styleUrls: ['./report-issues.component.scss']
})
export class ReportIssuesComponent {
  get emailAddress() {
    return 'serviciodeinformacion@howdengroup.com';
  }

  get subjectEmergencies() {
    const subject: string = $localize`: @@app.support.subject.emergencies:[Servicio de Información - Urgencias]`;
    return subject;
  }

  get subjectConsultations() {
    const subject: string = $localize`: @@app.support.subject.consultations:[Servicio de Información - Consultas]`;
    return subject;
  }

  get subjectErrors() {
    const subject: string = $localize`: @@app.support.subject.errors:[Servicio de Información - Reporte de error]`;
    return subject;
  }

  mailTo(subject: string) {
    location.href = `mailto:${this.emailAddress}?subject=${subject}`;
  }
}
