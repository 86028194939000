import { HowdenApiEnviroment, IHowdenApiInfo } from '@howdeniberia/core-front';
import { API_PATH_APPS } from 'src/app/core/constants/api-path-apps.constants';

export const ApiConfigurations: IHowdenApiInfo[] = [
  {
    name: API_PATH_APPS.API_INFORMATION_SERVICES,
    paths: {
      local: 'https://localhost:7198/',
      develop: 'https://localhost:7198/',
      staging: 'https://how-apiinformationservices.app-staging.howdeniberia.com/',
      production: 'https://how-apiinformationservices.app.howdeniberia.com/',
      devHowdenGroup: 'https://how-apiinformationservices.app-dev.howdeniberia.com/',
      preHowdenGroup: 'https://how-apiinformationservices.app-staging.howdeniberia.com/',
      proHowdenGroup: 'https://how-apiinformationservices.app.howdeniberia.com/'
    },
    useEnviroment: HowdenApiEnviroment.ENVIROMENT_CHOOSE,
    needsToken: true
  }
] as IHowdenApiInfo[];
