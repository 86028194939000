<div class="flex f-fd--column">
  <div>
    <howden-page-title></howden-page-title>
  </div>
  <div class="flex f-gap--10px margin-bottom-50 ">
    <mat-card class="f-basis--4 support-card" (click)="mailTo(subjectEmergencies)">
       <mat-card-header>
          <mat-card-title>
            <mat-icon>bolt</mat-icon>
            <br/>
            <span i18n="@@app.support.emergencies"> Urgencias</span>
          </mat-card-title>
        </mat-card-header>
    </mat-card>
    <mat-card class="f-basis--4 support-card" (click)="mailTo(subjectConsultations)">
       <mat-card-header>
          <mat-card-title>
            <mat-icon>contact_support</mat-icon>
            <br/>
            <span i18n="@@app.support.consultations"> Consultas</span>
          </mat-card-title>
        </mat-card-header>
    </mat-card>
    <mat-card class="f-basis--4 support-card" (click)="mailTo(subjectErrors)">
       <mat-card-header>
         <mat-card-title>
            <mat-icon>bug_report</mat-icon>
            <br/>
            <span i18n="@@app.support.errors"> Reportar error</span>
          </mat-card-title>
        </mat-card-header>
    </mat-card>
  </div>
  <div class="flex margin-left-50 margin-top-40">
    <h1 i18n="@@app.support.contact-data.title">Datos de contacto:</h1>
  </div>
  <div class="flex margin-left-50 margin-top-10">
      <span i18n="@@app.support.contact-data.person">Santiago Martínez</span>
  </div>
  <div class="flex margin-left-50 margin-top-10">
    <a href="mailto:{{emailAddress}}"  >
      {{emailAddress}}
    </a>
  </div>
  <div class="flex margin-left-50 margin-top-10">
    <span i18n="@@app.support.contact-data.address">Paseo de Recoletos 37, 4º Planta, Madrid</span>
  </div>
</div>
