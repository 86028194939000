import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { MenuHelperService } from '../../helpers';

@Injectable({
  providedIn: 'root'
})
export class CreditOpinionOptionGuard  {
  constructor(
    private menuHelperSrv: MenuHelperService,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.menuHelperSrv.checkCreditOpinionOption()) {
      this.router.navigate(['/monitoring']);
    }
    return true;
  }
}
