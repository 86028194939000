import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
  DialogResultTypes,
  HowdenAuthService,
  HowdenTableComponent,
  IButtonActionData,
  IDialogResult,
  IPageOf,
  ITableEventData
} from '@howdeniberia/core-front';
import { CreditOpinionOperation, CreditOpinionSituation, UserRole } from 'src/app/core/enums';
import { ICreditOpinion, ICreditOpinionCounter, ICreditOpinionSelection } from 'src/app/core/models/api/credit-opinion';
import { CreditOpinionContract } from 'src/app/core/services/api/credit-opinion';
import { ConfirmationDialogContract, UIBlockerContract } from 'src/app/core/services/ui';
import { FileUtils, Limits } from 'src/app/shared/utils';
import { SubSink } from 'subsink';

import { MatDialog } from '@angular/material/dialog';
import { tap } from 'rxjs';
import { UserDataContract } from 'src/app/core/services/api/user-data';
import { CreditOpinionEditDialogComponent } from '../../dialogs/credit-opinion-edit-dialog/pages/credit-opinion-edit-dialog.component';
import { CreditOpinionOperationDialogComponent } from '../../dialogs/credit-opinion-operation-dialog/pages/credit-opinion-operation-dialog.component';
import { CreditOpinionMapper } from '../../mappers';
import { CreditOpinionSearchViewModel } from '../../models';
import { ICreditOpinionViewModel } from '../../models/credit-opinion.viewmodel';

@Component({
  selector: 'howden-credit-opinion-search',
  templateUrl: './credit-opinion-search.component.html',
  styleUrls: ['./credit-opinion-search.component.scss']
})
export class CreditOpinionSearchComponent implements OnInit, OnDestroy {
  model = new CreditOpinionSearchViewModel();

  get minDate(): Date { return Limits.minDate; }

  isClientProfile = false;
  canAddOpinion = false;

  isOperationProfile = false;
  operation: CreditOpinionOperation | undefined | null;
  hasNoSelected = true;

  private _subscriptions = new SubSink();

  @ViewChild('creditOpinionsTable') creditOpinionsTable!: HowdenTableComponent;

  constructor(
    private router: Router,
    private authSrv: HowdenAuthService,
    private dialog: MatDialog,
    private confirmDialogSrv: ConfirmationDialogContract,
    private creditOpinionSrv: CreditOpinionContract,
    private userDataSrv: UserDataContract,
    private uiBlockerSrv: UIBlockerContract
  ) {
  }

  ngOnInit(): void {
    this.isClientProfile = this.authSrv.isUserAuthorized([
      UserRole.Association,
      UserRole.AssociationMember,
      UserRole.IndependentClient
    ]) && !this.authSrv.isSuperAdmin;

    this.canAddOpinion = this.isClientProfile;

    this.isOperationProfile = this.authSrv.isUserAuthorized([
      UserRole.Administrator
    ]);

    if (!this.isClientProfile) {
      this.uiBlockerSrv.block();
      this.userDataSrv.getClients(false).subscribe((data) => {
        this.model.clients = data;
        this.refresh();
        this.onChanges();
        this.uiBlockerSrv.unblock();
      });
    } else {
      this.refresh();
      this.onChanges();
      this.onUpdateCounter();
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onServerSideConfigChanged(event: ITableEventData): void {
    this.model.updateServerSideConfig(event);
    this.refresh();
  }

  onNew(): void {
    const inputData = { id: null };
    this.uiBlockerSrv.block();

    this._subscriptions.sink = this.creditOpinionSrv.getCounter().pipe(
      tap((data: ICreditOpinionCounter) => {
        //Actualizamos valores
        this.model.creditOpinionCounter = data;
        this.model.counterMsg = data.message ?? '';
        if (!data.unlimited && data.actualNumber >= data.maxNumber) {
          const title: string = $localize`: @@app.credit-opinion.search.limit.reached.title:
          Has alcanzado el límite de solicitudes de tu plan`;
          let question: string = $localize`: @@app.credit-opinion.search.limit.reached.subtitle:
          La solicitud adicional realizada se le facturará a final de mes por el importe de XXX€, ¿desea continuar?`;

          question = question.replace('XXX', data.priceExceeded.toString());

          this.uiBlockerSrv.unblock();

          this.confirmDialogSrv.openDanger(title, question).subscribe((result) => {
            if (result && result.result === DialogResultTypes.Yes) {
              this.showEditDialog(inputData);
            }
          });
        } else {
          this.showEditDialog(inputData);
        }
      })
    ).subscribe();
  }

  onRowClicked(event: ICreditOpinionViewModel): void {
    if (this.isClientProfile) {
      const inputData = { id: event.id };
      this.showEditDialog(inputData);
    } else {
      this.router.navigate(['credit-opinion/edit', event.id]);
    }
  }

  onAction(event: IButtonActionData): void {
    if (event.buttonName === 'delete') {
      this.onDelete(event);
    } else if (event.buttonName === 'download') {
      this.onDownload(event);
    }
  }

  onSituationChanged() {
    if (this.isOperationProfile) {
      if (this.model.situation === CreditOpinionSituation.Answered) {
        this.operation = CreditOpinionOperation.Unit4;
      } else {
        this.operation = null;
      }
    } else {
      this.operation = null;
    }
  }

  onSelectionChanged(event: any[]) {
    this.hasNoSelected = event.length === 0;
  }

  onExecuteOperation() {
    const inputData = {
      operation: this.operation,
      downloadUnit4: this.isUnit4,
      creditOpinionIds: this.creditOpinionsTable.selectedRows()
    } as ICreditOpinionSelection;

    const dialogRef = this.dialog.open(CreditOpinionOperationDialogComponent, {
      minHeight: '300px',
      width: '500px',
      data: inputData
    });

    this._subscriptions.sink = dialogRef.afterClosed().subscribe(_ => {
      this.hasNoSelected = this.creditOpinionsTable.selectedRows.length === 0;
      this.refresh();
    });
  }

  get isUnit4() {
    return this.operation === CreditOpinionOperation.Unit4;
  }

  private onChanges(): void {
    this._subscriptions.sink = this.model.filterForm.valueChanges.subscribe(() => {
      this.model.searchRequest.pageNumber = 0;
      this.model.updateServerSideFilters();
      this.refresh();
    });
  }

  private refresh(): void {
    if (this.model.filterForm.valid) {
      this.model.updateServerSideFilters();

      this.uiBlockerSrv.block();

      this.creditOpinionSrv.search(this.model.searchRequest).subscribe({
        next: (searchResult: IPageOf<ICreditOpinion>) => {
          this.model.length = searchResult.totalCount;
          this.model.data = searchResult.data?.map((source) =>
            CreditOpinionMapper.mapForSearch(source, this.isOperationProfile, this.model.situation));
        },
        complete: () => {
          //Actualizamos para que se desactive el botón de las operaciones, si lo hay
          this.hasNoSelected = this.creditOpinionsTable.selectedRows.length === 0;
          this.uiBlockerSrv.unblock();
        }
      });
    }
  }

  private onUpdateCounter() {
    if (this.isClientProfile) {
      this.creditOpinionSrv.getCounter().subscribe((data) => {
        this.model.creditOpinionCounter = data;
        this.model.counterInfo = `${data.actualNumber} / ${data.maxNumber}`;
        this.model.counterMsg = data.message ?? '';

        if (!data.unlimited && data.maxNumber === 0) {
          this.canAddOpinion = false;
        }
      });
    }
  }

  private showEditDialog(inputData: any) {
    const dialogRef = this.dialog.open(CreditOpinionEditDialogComponent, {
      minWidth: '900px',
      width: 'auto',
      data: inputData
    });

    this._subscriptions.sink = dialogRef
      .afterClosed()
      .pipe(
        tap((dialogResult?: IDialogResult<void>) => {
          if (dialogResult && dialogResult.result === DialogResultTypes.OK) {
            this.onUpdateCounter();
            this.refresh();
          }
        })
      ).subscribe();
  }

  private onDelete(event: IButtonActionData): void {
    const creditOpinionId = event.row.id;
    const title: string = $localize`: @@app.credit-opinion.search.delete.title:Borrado de Solicitud`;
    const question: string = $localize`: @@app.credit-opinion.search.delete.subtitle: Se borrará la solicitud de opinión de crédito, ¿desea continuar?`;

    this._subscriptions.sink = this.confirmDialogSrv.openDanger(title, question).pipe(
      tap((result: IDialogResult<boolean>) => {
        if (result && result.result === DialogResultTypes.Yes) {
          const subtitle: string = $localize`: @@app.credit-opinion.search.delete..confirm.subtitle:Finalizado Correctamente`;

          this.uiBlockerSrv.block();
          this.creditOpinionSrv.delete(creditOpinionId).pipe(
            tap({
              complete: () => {
                this._subscriptions.sink = this.confirmDialogSrv.openDefault(title, subtitle).subscribe(() => {
                  this.uiBlockerSrv.unblock();
                  this.onUpdateCounter();
                  this.refresh();
                });
              }
            })
          ).subscribe();
        }
      })
    ).subscribe();
  }

  private onDownload(event: IButtonActionData) {
    this.uiBlockerSrv.block();

    const creditOpinionId = event.row.id;

    this.creditOpinionSrv.downloadPdf(creditOpinionId).subscribe(
      {
        next: (blob: Blob) => {
          const attachName = FileUtils.formatFilename(`CreditOpinion_${event.row.companyAssessFiscalCode}`, 'pdf');
          FileUtils.downloadFile(
            attachName,
            blob
          );
        },
        complete: () => {
          this.uiBlockerSrv.unblock();
        }
      }
    );
  }
}
