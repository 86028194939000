import { KeyValue } from '@angular/common';
import { FormControl, FormGroup } from '@angular/forms';
import {
  ColumnAligment,
  ColumnPipeTypes,
  IHowdenColumnConfig,
  ITableButtonConfig,
  TableButtonColors
} from '@howdeniberia/core-front';
import { DATA_STATUS_VALUES } from 'src/app/core/constants';
import { DataStatus } from 'src/app/core/enums';
import { IRecruitmentPlanFilter } from 'src/app/core/models/api/recruitment-plan';
import { IUserDataReduced } from 'src/app/core/models/api/user-data';
import { IRecruitmentPlanViewModel } from '.';

export class RecruitmentPlanSearchViewModel {
  private _filterForm: FormGroup;
  private _statusValues: KeyValue<DataStatus, string>[] = DATA_STATUS_VALUES;
  private _associations: Array<IUserDataReduced> = [];
  private _searchRequest: IRecruitmentPlanFilter = {};
  private _sortedBy?: string;
  private _sortDirection?: string;
  private _length = 0;

  readonly C_DESCRIPTION = 'description';
  readonly C_OBSERVATIONS = 'observations';
  readonly C_ASSOCIATION = 'associationId';
  readonly C_STATUS = 'status';

  constructor() {
    this._filterForm = new FormGroup({
      [this.C_DESCRIPTION]: new FormControl('', []),
      [this.C_OBSERVATIONS]: new FormControl('', []),
      [this.C_ASSOCIATION]: new FormControl(null, []),
      [this.C_STATUS]: new FormControl(DataStatus.Active, [])
    });
  }

  data: IRecruitmentPlanViewModel[] = [];
  columns: Array<IHowdenColumnConfig> = [
    {
      fieldName: 'description',
      fieldHeader: $localize`:@@app.recruitment-plan.search.description.column:Descripción`,
      canSort: true,
      width: '12%'
    },
    {
      fieldName: 'creditOpinionLimit',
      fieldHeader: $localize`:@@app.recruitment-plan.search.creditOpinionLimit.column:Nº opiniones`,
      canSort: true,
      width: '8%',
      aligment: ColumnAligment.CENTER,
      pipeToApply: ColumnPipeTypes.NUMBER
    },
    {
      fieldName: 'monitoringLimit',
      fieldHeader: $localize`:@@app.recruitment-plan.search.monitoringLimit.column:Nº monitorizaciones`,
      canSort: true,
      width: '10%',
      aligment: ColumnAligment.CENTER,
      pipeToApply: ColumnPipeTypes.NUMBER
    },
    {
      fieldName: 'monthlyPrice',
      fieldHeader: $localize`:@@app.recruitment-plan.search.monthlyPrice.column:Cuota mensual`,
      canSort: true,
      width: '8%',
      aligment: ColumnAligment.CENTER,
      pipeToApply: ColumnPipeTypes.CURRENCY
    },
    {
      fieldName: 'creditOpinionPrice',
      fieldHeader: $localize`:@@app.recruitment-plan.search.email.creditOpinionPrice:Precio opinión`,
      canSort: true,
      width: '8%',
      aligment: ColumnAligment.CENTER,
      pipeToApply: ColumnPipeTypes.CURRENCY
    },
    {
      fieldName: 'onlyCreditOpinion',
      fieldHeader: $localize`:@@app.recruitment-plan.search.email.onlyCreditOpinion:Sólo opiniones`,
      canSort: true,
      width: '8%',
      aligment: ColumnAligment.CENTER,
      pipeToApply: ColumnPipeTypes.YESNO
    },
    {
      fieldName: 'onlyMonitoring',
      fieldHeader: $localize`:@@app.recruitment-plan.search.email.onlyMonitoring:Sólo monitorizaciones`,
      canSort: true,
      width: '10%',
      aligment: ColumnAligment.CENTER,
      pipeToApply: ColumnPipeTypes.YESNO
    },
    {
      fieldName: 'association',
      fieldHeader: $localize`:@@app.recruitment-plan.search.association.column:Asociación`,
      canSort: true,
      width: '10%'
    },
    {
      fieldName: 'startDate',
      fieldHeader: $localize`:@@app.recruitment-plan.search.startDate.column:Inicio`,
      canSort: true,
      width: '7%',
      aligment: ColumnAligment.CENTER,
      pipeToApply: ColumnPipeTypes.DATE
    },
    {
      fieldName: 'endDate',
      fieldHeader: $localize`:@@app.recruitment-plan.search.endDate.column:Fin`,
      canSort: true,
      width: '7%',
      aligment: ColumnAligment.CENTER,
      pipeToApply: ColumnPipeTypes.DATE
    },
    {
      fieldName: 'status',
      fieldHeader: $localize`:@@app.recruitment-plan.search.status.column:Estado`,
      canSort: true,
      width: '5%'
    }
  ];

  buttons: Array<ITableButtonConfig> = [
    {
      name: 'download',
      color: TableButtonColors.INFO,
      icon: 'download',
      tooltip: $localize`:@@app.recruitment-plan.search.actions.download.tooltip:Descargar Contrato`,
      isDisabled: (row: any): boolean => (typeof row.contractName === 'undefined' || row.contractName === null || row.contractName === '')
    }
  ];

  get length(): number {
    return this._length;
  }

  set length(length: number) {
    this._length = length;
  }

  get sortedBy(): string | undefined {
    return this._sortedBy;
  }

  get sortDirection(): string | undefined {
    return this._sortDirection;
  }

  get statusValues(): KeyValue<DataStatus, string>[] {
    return this._statusValues;
  }

  get associations(): Array<IUserDataReduced> {
    return this._associations;
  }
  set associations(value: Array<IUserDataReduced>) {
    this._associations = value;
  }

  get searchRequest(): IRecruitmentPlanFilter {
    return this._searchRequest;
  }

  get filterForm(): FormGroup {
    return this._filterForm;
  }

  set filterForm(value: FormGroup) {
    this._filterForm = value;
  }

  get description(): string | null | undefined {
    return this._filterForm.get(this.C_DESCRIPTION)?.value;
  }
  set description(value: string | null | undefined) {
    this._filterForm.get(this.C_DESCRIPTION)?.setValue(value);
  }

  get observations(): string | null | undefined {
    return this._filterForm.get(this.C_OBSERVATIONS)?.value;
  }
  set observations(value: string | null | undefined) {
    this._filterForm.get(this.C_OBSERVATIONS)?.setValue(value);
  }

  get associationId(): string | null | undefined {
    return this._filterForm.get(this.C_ASSOCIATION)?.value;
  }
  set associationId(value: string | null | undefined) {
    this._filterForm.get(this.C_ASSOCIATION)?.setValue(value);
  }

  get status(): number | null | undefined {
    return this._filterForm.get(this.C_STATUS)?.value;
  }
  set status(value: number | null | undefined) {
    this._filterForm.get(this.C_STATUS)?.setValue(value);
  }

  updateServerSideFilters(): void {
    this.searchRequest.description = this.description;
    this.searchRequest.observations = this.observations;
    this.searchRequest.associationId = this.associationId;
    this.searchRequest.status = this.status;
  }
}
