import { Injectable } from '@angular/core';
import { HowdenHttpClientService, IPageOf, IResponse } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { API_PATH_APPS } from 'src/app/core/constants';
import { IDebtor, IDebtorCounter, IDebtorFilter } from 'src/app/core/models/api/monitoring';
import { DebtorContract } from './debtor.contract';

@Injectable({
  providedIn: 'root'
})
export class DebtorService implements DebtorContract {
  apiName = API_PATH_APPS.API_INFORMATION_SERVICES;
  basePath = 'api/debtor';

  constructor(
    private httpClient: HowdenHttpClientService
  ) { }

  search(request: IDebtorFilter): Observable<IPageOf<IDebtor>> {
    return this.httpClient.post<IPageOf<IDebtor>>(this.apiName, `${this.basePath}/search`, request);
  }

  get(id: string): Observable<IDebtor> {
    return this.httpClient.get<IDebtor>(this.apiName, `${this.basePath}/${id}`);
  }

  save(request: IDebtor): Observable<IResponse<string>> {
    return this.httpClient.post<IResponse<string>>(this.apiName, this.basePath, request);
  }

  getCounter(): Observable<IDebtorCounter> {
    return this.httpClient.get<IDebtorCounter>(this.apiName, `${this.basePath}/counter`);
  }
}
