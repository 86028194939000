{
  "name": "howden-information-services",
  "version": "v2.0.0",
  "description": "",
  "title": "Plataforma de Información",
  "languages": [
    "es"
  ],
  "copyright": "Copyright 2015-2024 | Howden Iberia | Todos los derechos reservados",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "start-Development": "ng serve -c develop",
    "start-Staging": "ng serve -c staging",
    "start-Production": "ng serve -c production",
    "start-DevelopmentHowdenGroup": "ng serve -c devHowdenGroup",
    "start-StagingHowdenGroup": "ng serve -c preHowdenGroup",
    "start-ProductionHowdenGroup": "ng serve -c proHowdenGroup",
    "build": "ng build",
    "build-Development": "ng build -c development",
    "build-Staging": "ng build -c staging",
    "build-Production": "ng build -c production",
    "build-DevelopmentHowdenGroup": "ng build --configuration devHowdenGroup",
    "build-StagingHowdenGroup": "ng build --configuration preHowdenGroup",
    "build-ProductionHowdenGroup": "ng build --configuration proHowdenGroup",
    "watch": "ng build --watch -c development",
    "test": "ng test",
    "lint": "eslint . --ext .ts",
    "lint-and-fix": "eslint . --ext .ts --fix",
    "i18n-extract": "ng extract-i18n --output-path=src/locale --progress=true"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.12",
    "@angular/cdk": "^18.2.14",
    "@angular/common": "^18.2.12",
    "@angular/compiler": "^18.2.12",
    "@angular/core": "^18.2.12",
    "@angular/forms": "^18.2.12",
    "@angular/material": "^18.2.14",
    "@angular/material-moment-adapter": "^18.2.14",
    "@angular/platform-browser": "^18.2.12",
    "@angular/platform-browser-dynamic": "^18.2.12",
    "@angular/router": "^18.2.12",
    "@howdeniberia/core-front": "^2.18.8",
    "echarts": "^5.5.1",
    "moment": "^2.30.1",
    "ngx-cookie-service": "^18.0.0",
    "ngx-currency": "^18.0.0",
    "ngx-echarts": "^18.0.0",
    "ngx-toastr": "~18.0.0",
    "nif-dni-nie-cif-validation": "^1.0.10",
    "rxjs": "~7.8.1",
    "subsink": "^1.0.2",
    "tslib": "^2.7.0",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.12",
    "@angular-devkit/core": "^18.2.12",
    "@angular-devkit/schematics": "^18.2.12",
    "@angular-eslint/builder": "^18.3.1",
    "@angular-eslint/eslint-plugin": "^18.3.1",
    "@angular-eslint/eslint-plugin-template": "^18.3.1",
    "@angular-eslint/schematics": "^18.3.1",
    "@angular-eslint/template-parser": "^18.3.1",
    "@angular/cli": "^18.2.12",
    "@angular/compiler-cli": "^18.2.12",
    "@angular/localize": "^18.2.12",
    "@schematics/angular": "^18.2.12",
    "@types/echarts": "^4.9.22",
    "@types/jasmine": "^5.1.4",
    "@types/node": "^22.4.2",
    "@typescript-eslint/eslint-plugin": "^7.2.0",
    "@typescript-eslint/parser": "^7.2.0",
    "@typescript-eslint/utils": "^7.16.0",
    "eslint": "^8.57.0",
    "eslint-plugin-rxjs": "^5.0.3",
    "jasmine-core": "^5.1.2",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "^3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-firefox-launcher": "~2.1.2",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "^2.1.0",
    "typescript": "~5.4.5"
  }
}