import { Injectable } from '@angular/core';
import { HowdenHttpClientService, IPageOf, IResponse } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { API_PATH_APPS } from 'src/app/core/constants';
import {
  IDebtorCreditGranted,
  IDebtorCreditGrantedFilter,
  IDebtorCreditGrantedUpdated,
  IMonitoringUserComplete,
  IMonitoringUserReduced
} from 'src/app/core/models/api/monitoring';
import { MonitoringContract } from './monitoring.contract';

@Injectable({
  providedIn: 'root'
})
export class MonitoringService implements MonitoringContract {
  apiName = API_PATH_APPS.API_INFORMATION_SERVICES;
  basePath = 'api/monitoring';

  constructor(
    private httpClient: HowdenHttpClientService
  ) { }

  getMonitorableClients(): Observable<Array<IMonitoringUserReduced>> {
    return this.httpClient.get<Array<IMonitoringUserReduced>>(
      this.apiName,
      `${this.basePath}/debtor-credit-granted/users`
    );
  }

  searchDebtorCreditGranted(request: IDebtorCreditGrantedFilter): Observable<IPageOf<IDebtorCreditGranted>> {
    return this.httpClient.post<IPageOf<IDebtorCreditGranted>>(
      this.apiName,
      `${this.basePath}/debtor-credit-granted/search`,
      request
    );
  }

  getDebtorCreditGranted(id: string): Observable<IDebtorCreditGranted> {
    return this.httpClient.get<IDebtorCreditGranted>(
      this.apiName,
      `${this.basePath}/debtor-credit-granted/${id}`
    );
  }

  saveDebtorCreditGranted(request: IDebtorCreditGranted): Observable<IResponse<boolean>> {
    return this.httpClient.post<IResponse<boolean>>(
      this.apiName,
      `${this.basePath}/debtor-credit-granted`,
      request
    );
  }

  notifyDebtorCreditGrantedChanges(request: IDebtorCreditGrantedUpdated): Observable<IResponse<boolean>> {
    return this.httpClient.post<IResponse<boolean>>(
      this.apiName,
      `${this.basePath}/debtor-credit-granted/notify-changes`,
      request
    );
  }

  getCompleteMonitoring(userId: number): Observable<IMonitoringUserComplete> {
    return this.httpClient.get<IMonitoringUserComplete>(
      this.apiName,
      `${this.basePath}/complete/${userId}`
    );
  }

  downloadMonitoringHistorical(userId: number): Observable<Blob> {
    return this.httpClient.getBlob(
      this.apiName,
      `${this.basePath}/download-historical/${userId}`,
      {
        reportProgress: true,
        responseType: 'blob'
      }
    );
  }
}
