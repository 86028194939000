<form [formGroup]="model.form" class="howden-dialog-content">
  <div mat-dialog-title class="title-text" i18n="@@app.debtor-credit-granted.edit.dialog.title">Establecer Crédito Concedido</div>

  <mat-dialog-content>
    <div class="flex f-gap--10px">
      <mat-form-field class="f-basis--6">
        <mat-label i18n="@@app.debtor-credit-granted.edit.dialog.fiscalCode">ID Fiscal</mat-label>
        <input
          matInput
          [formControlName]="model.C_FISCAL_CODE"
          [howdenControlError]="fiscalCodeError"
          maxlength="20"
        />
        <mat-error #fiscalCodeError></mat-error>
      </mat-form-field>
        <howden-autocomplete
        class="f-basis--6"
        i18n-label="@@app.debtor-credit-granted.edit.dialog.country"
        label="País"
        displayField="name"
        keyField="id"
        [selectableItems]="model.countries"
        [formControlName]="model.C_COUNTRY_ID"
      >
      </howden-autocomplete>
    </div>

    <div class="flex f-gap--10px">
      <mat-form-field class="f-basis--12">
        <mat-label i18n="@@app.debtor-credit-granted.edit.dialog.businessName">Razón social</mat-label>
        <input
          matInput
          [formControlName]="model.C_BUSINESS_NAME"
          [howdenControlError]="businessNameError"
          maxlength="60"
        />
        <mat-error #businessNameError></mat-error>
      </mat-form-field>
    </div>

    <div class="flex f-gap--10px">
      <howden-decimal
          class="f-basis--6"
          [formControlName]="model.C_REQUESTED_AMOUNT"
          i18n-label="@@app.debtor-credit-granted.edit.dialog.requestedAmount"
          label="Solicitado"
      ></howden-decimal>
      <mat-form-field class="f-basis--6">
        <mat-label i18n="@@app.debtor-credit-granted.edit.dialog.lastGrantedAmount">Última cantidad concedida</mat-label>
        <input matInput [formControlName]="model.C_LAST_GRANTED_AMOUNT"/>
      </mat-form-field>
    </div>

    <div class="flex f-gap--10px">
      <mat-form-field class="f-basis--6">
        <mat-label i18n="@@app.debtor-credit-granted.edit.dialog.monitoringDate">Fecha monitorización</mat-label>
        <input matInput
          [matDatepicker]="monitoringDatePicker"
          [formControlName]="model.C_MONITORING_DATE"
          [howdenControlError]="monitoringDateError"
          [min]="minDate" />
        <mat-error #monitoringDateError></mat-error>
        <mat-datepicker-toggle matSuffix [for]="monitoringDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #monitoringDatePicker></mat-datepicker>
      </mat-form-field>
      <howden-decimal
        class="f-basis--6"
        [formControlName]="model.C_GRANTED_AMOUNT"
        i18n-label="@@app.debtor-credit-granted.edit.dialog.grantedAmount"
        label="Concedido"
      ></howden-decimal>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button type="button" mat-raised-button (click)="onCancel()">
      <span i18n="@@app.core.actions.cancel">Cancelar</span>
    </button>
    <button type="button" color="primary" mat-raised-button [disabled]="model.form.invalid" (click)="onAccept()">
      <span i18n="@@app.core.actions.accept">Aceptar</span>
    </button>
  </mat-dialog-actions>
</form>
