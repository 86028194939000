import { KeyValue } from '@angular/common';

import { Grade } from '../enums';

export const GRADE_VALUES: KeyValue<Grade, string>[] = [
  {
    key: Grade.NA,
    value: $localize`:@@app.route.grade.enums.na:NA`
  },
  {
    key: Grade.Excelent,
    value: $localize`:@@app.route.grade.enums.excelent:Excelente`
  },
  {
    key: Grade.VeryGood,
    value: $localize`:@@app.route.grade.enums.veryGood:Muy bueno`
  },
  {
    key: Grade.Good,
    value: $localize`:@@app.route.grade.enums.good:Bueno`
  },
  {
    key: Grade.Acceptable,
    value: $localize`:@@app.route.grade.enums.acceptable:Aceptable`
  },
  {
    key: Grade.Regular,
    value: $localize`:@@app.route.grade.enums.regular:Regular`
  },
  {
    key: Grade.Adequate,
    value: $localize`:@@app.route.grade.enums.adequate:Suficiente`
  },
  {
    key: Grade.Inadequate,
    value: $localize`:@@app.route.grade.enums.inadequate:Insuficiente`
  },
  {
    key: Grade.Deficient,
    value: $localize`:@@app.route.grade.enums.deficient:Deficiente`
  },
  {
    key: Grade.VeryDeficient,
    value: $localize`:@@app.route.grade.enums.veryDeficient:Muy deficiente`
  },
  {
    key: Grade.Catastrofic,
    value: $localize`:@@app.route.grade.enums.catastrofic:Catastrófico`
  }
] as KeyValue<Grade, string>[];
