<form [formGroup]="model.form" class="howden-dialog-content">
  <div mat-dialog-title class="title-text" i18n="@@app.debtor.edit.dialog.title">Deudor Monitorizaciones</div>

  <mat-dialog-content>
    <div class="flex f-gap--10px">
      <mat-form-field class="f-basis--6">
        <mat-label i18n="@@app.debtor.edit.dialog.fiscalCodeType">Tipo ID Fiscal</mat-label>
        <mat-select
          [formControlName]="model.C_FISCAL_CODE_TYPE"
          [howdenControlError]="fiscalCodeTypeError"
        >
          <mat-option></mat-option>
          @for (item of model.fiscalCodeTypeValues | howdenArraySort : 'value'; track item.key) {
            <mat-option [value]="item.key">
              {{ item.value }}
            </mat-option>
          }
        </mat-select>
        <mat-error #fiscalCodeTypeError></mat-error>
      </mat-form-field>

      <mat-form-field class="f-basis--6">
        <mat-label i18n="@@app.debtor.edit.dialog.fiscalCode">ID Fiscal</mat-label>
        <input
          matInput
          [formControlName]="model.C_FISCAL_CODE"
          [howdenControlError]="fiscalCodeError"
          maxlength="20"
        />
        <mat-error #fiscalCodeError></mat-error>
      </mat-form-field>
    </div>

    @if (this.model.form.errors?.['fiscalCodeInvalid']) {
      <div class="flex f-gap--10px margin-bottom-10 small-letter">
        <mat-error i18n="@@app.validators.fiscal-code-invalid">
          El identificador fiscal no es válido
        </mat-error>
      </div>
    }

    <div class="flex f-gap--10px">
      @if (needCountry) {
        <howden-autocomplete
          class="f-basis--6"
          i18n-label="@@app.debtor.edit.dialog.country"
          label="País"
          displayField="name"
          keyField="id"
          [selectableItems]="model.countries"
          [formControlName]="model.C_COUNTRY_ID"
        >
        </howden-autocomplete>
      } @else {
        <div  class="f-basis--6"></div>
      }
      <mat-form-field class="f-basis--6">
        <mat-label i18n="@@app.debtor.edit.dialog.ownIdentifier">Identificador interno</mat-label>
        <input
          matInput
          [formControlName]="model.C_OWN_IDENTIFIER"
          [howdenControlError]="ownIdentifierError"
          maxlength="50"
        />
        <mat-error #ownIdentifierError></mat-error>
      </mat-form-field>
    </div>

    <div class="flex f-gap--10px">
      <mat-form-field class="f-basis--12">
        <mat-label i18n="@@app.debtor.edit.dialog.businessName">Razón social</mat-label>
        <input
          matInput
          [formControlName]="model.C_BUSINESS_NAME"
          [howdenControlError]="businessNameError"
          maxlength="60"
        />
        <mat-error #businessNameError></mat-error>
      </mat-form-field>
    </div>

    <div class="flex f-gap--10px">
      <howden-decimal
        class="f-basis--6"
        [formControlName]="model.C_REQUESTED_AMOUNT"
        i18n-label="@@app.debtor.edit.dialog.requestedAmount"
        label="Solicitado"
      ></howden-decimal>

      <mat-slide-toggle
        color="primary"
        class="f-basis--6 margin-top-20"
        [formControlName]="model.C_NOT_MONITORABLE"
        i18n="@@app.debtor.edit.dialog.notMonitorable"
      >
        Dejar de hacer seguimiento
      </mat-slide-toggle>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button type="button" mat-raised-button (click)="onCancel()">
      <span i18n="@@app.core.actions.cancel">Cancelar</span>
    </button>
    <button type="button" color="primary" mat-raised-button [disabled]="model.form.invalid || !isClientProfile" (click)="onAccept()">
      <span i18n="@@app.core.actions.accept">Aceptar</span>
    </button>
  </mat-dialog-actions>
</form>
