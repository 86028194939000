import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  ColumnAligment,
  ColumnPipeTypes,
  IHowdenColumnConfig
} from '@howdeniberia/core-front';
import { IMonitoringDebtor } from 'src/app/core/models/api/monitoring';
import { IUserDataReduced } from 'src/app/core/models/api/user-data';
import { IDebtorMonitoringActualViewModel, IDebtorMonitoringHistoricalViewModel } from '.';

export class DebtorMonitoringViewModel {
  private _filterForm: FormGroup;
  private _clients: Array<IUserDataReduced> = [];

  readonly C_USER_ID = 'userId';

  constructor() {
    this._filterForm = new FormGroup({
      [this.C_USER_ID]: new FormControl(null, [Validators.required])
    });
  }

  monitoringDebtorsData: Array<IMonitoringDebtor> = [];

  dataActualMonitoring: IDebtorMonitoringActualViewModel[] = [];
  columnsActualMonitoring: IHowdenColumnConfig[] = [
    {
      fieldName: 'fiscalCode',
      fieldHeader: $localize`:@@app.monitoring.debtor.fiscalCode:ID Fiscal`,
      canSort: true,
      width: '15%'
    },
    {
      fieldName: 'businessName',
      fieldHeader: $localize`:@@app.monitoring.debtor.businessName:Razón social`,
      canSort: true,
      width: '28%'
    },
    {
      fieldName: 'ownIdentifier',
      fieldHeader: $localize`:@@app.monitoring.debtor.ownIdentifier:Identificador interno`,
      canSort: true,
      width: '20%'
    },
    {
      fieldName: 'requestedAmount',
      fieldHeader: $localize`:@@app.monitoring.debtor.requestedAmount:Solicitado`,
      canSort: true,
      width: '15%',
      aligment: ColumnAligment.RIGHT,
      pipeToApply: ColumnPipeTypes.NUMBER
    },
    {
      fieldName: 'grantedAmount',
      fieldHeader: $localize`:@@app.monitoring.debtor.grantedAmount:Concedido`,
      canSort: true,
      width: '15%',
      aligment: ColumnAligment.RIGHT,
      pipeToApply: ColumnPipeTypes.NUMBER
    }
  ];

  dataHistoricalMonitoring: IDebtorMonitoringHistoricalViewModel[] = [];
  columnsHistoricalMonitoring: IHowdenColumnConfig[] = [
    {
      fieldName: 'fiscalCode',
      fieldHeader: $localize`:@@app.monitoring.debtor.fiscalCode:ID Fiscal`,
      canSort: true,
      width: '8%'
    },
    {
      fieldName: 'businessName',
      fieldHeader: $localize`:@@app.monitoring.debtor.businessName:Razón social`,
      canSort: true,
      width: '12%'
    },
    {
      fieldName: 'ownIdentifier',
      fieldHeader: $localize`:@@app.monitoring.debtor.ownIdentifier:Identificador interno`,
      canSort: true,
      width: '8%'
    },
    {
      fieldName: 'requestedAmount',
      fieldHeader: $localize`:@@pp.monitoring.debtor.requestedAmount:Solicitado`,
      canSort: true,
      width: '7%',
      aligment: ColumnAligment.RIGHT,
      pipeToApply: ColumnPipeTypes.NUMBER
    },
    {
      fieldName: 'grantedAmount1',
      fieldHeader: '',
      canSort: false,
      width: '5%',
      aligment: ColumnAligment.RIGHT,
      pipeToApply: ColumnPipeTypes.NUMBER
    },
    {
      fieldName: 'grantedAmount2',
      fieldHeader: '',
      canSort: false,
      width: '5%',
      aligment: ColumnAligment.RIGHT,
      pipeToApply: ColumnPipeTypes.NUMBER
    },
    {
      fieldName: 'grantedAmount3',
      fieldHeader: '',
      canSort: false,
      width: '5%',
      aligment: ColumnAligment.RIGHT,
      pipeToApply: ColumnPipeTypes.NUMBER
    },
    {
      fieldName: 'grantedAmount4',
      fieldHeader: '',
      canSort: false,
      width: '5%',
      aligment: ColumnAligment.RIGHT,
      pipeToApply: ColumnPipeTypes.NUMBER
    },
    {
      fieldName: 'grantedAmount5',
      fieldHeader: '',
      canSort: false,
      width: '5%',
      aligment: ColumnAligment.RIGHT,
      pipeToApply: ColumnPipeTypes.NUMBER
    },
    {
      fieldName: 'grantedAmount6',
      fieldHeader: '',
      canSort: false,
      width: '5%',
      aligment: ColumnAligment.RIGHT,
      pipeToApply: ColumnPipeTypes.NUMBER
    },
    {
      fieldName: 'grantedAmount7',
      fieldHeader: '',
      canSort: false,
      width: '5%',
      aligment: ColumnAligment.RIGHT,
      pipeToApply: ColumnPipeTypes.NUMBER
    },
    {
      fieldName: 'grantedAmount8',
      fieldHeader: '',
      canSort: false,
      width: '5%',
      aligment: ColumnAligment.RIGHT,
      pipeToApply: ColumnPipeTypes.NUMBER
    },
    {
      fieldName: 'grantedAmount9',
      fieldHeader: '',
      canSort: false,
      width: '5%',
      aligment: ColumnAligment.RIGHT,
      pipeToApply: ColumnPipeTypes.NUMBER
    },
    {
      fieldName: 'grantedAmount10',
      fieldHeader: '',
      canSort: false,
      width: '5%',
      aligment: ColumnAligment.RIGHT,
      pipeToApply: ColumnPipeTypes.NUMBER
    },
    {
      fieldName: 'grantedAmount11',
      fieldHeader: '',
      canSort: false,
      width: '5%',
      aligment: ColumnAligment.RIGHT,
      pipeToApply: ColumnPipeTypes.NUMBER
    },
    {
      fieldName: 'grantedAmount12',
      fieldHeader: '',
      canSort: false,
      width: '5%',
      aligment: ColumnAligment.RIGHT,
      pipeToApply: ColumnPipeTypes.NUMBER
    }
  ];

  getControl(controlName: string): AbstractControl<any, any> {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this._filterForm.get(controlName)!;
  }

  get clients(): Array<IUserDataReduced> {
    return this._clients;
  }
  set clients(value: Array<IUserDataReduced>) {
    this._clients = value;
  }

  public get filterForm(): FormGroup {
    return this._filterForm;
  }

  public set filterForm(value: FormGroup) {
    this._filterForm = value;
  }

  get userId(): number | null | undefined {
    return this.getControl(this.C_USER_ID)?.value;
  }
  set userId(value: number | null | undefined) {
    this.getControl(this.C_USER_ID)?.setValue(value);
  }
}
