<div class="flex f-fd--column">
  <div>
    <howden-page-title></howden-page-title>
  </div>

  <form [formGroup]="model.filterForm">
    <div class="flex f-gap--10px margin-bottom-10">
      @if (!isClientProfile) {
        <howden-autocomplete
          class="f-basis--4"
          i18n-label="@@app.credit-opinion.search.userId"
          label="Solicitante"
          displayField="description"
          keyField="userId"
          [selectableItems]="model.clients"
          [formControlName]="model.C_REQUEST_USER_ID"
        >
        </howden-autocomplete>
      }

      <mat-form-field class="f-basis--2">
        <mat-label i18n="@@app.credit-opinion.search.companyAssessFiscalCode">NIF</mat-label>
        <input  matInput
          [formControlName]="model.C_COMPANY_ASSESS_FISCAL_CODE"
          [howdenControlError]="companyAssessFiscalCodeError"
          maxlength="20"
        />
        <mat-error #companyAssessFiscalCodeError></mat-error>
      </mat-form-field>

      <mat-form-field class="f-basis--2">
        <mat-label i18n="@@app.credit-opinion.search.companyAssessName">Sociedad</mat-label>
        <input matInput
          [formControlName]="model.C_COMPANY_ASSESS_NAME"
          [howdenControlError]="companyAssessNameError"
          maxlength="60"
        />
        <mat-error #companyAssessNameError></mat-error>
      </mat-form-field>

      <mat-form-field class="f-basis--2">
        <mat-label i18n="@@app.credit-opinion.search.DateFrom">Desde</mat-label>
        <input matInput
          [matDatepicker]="dateFromPicker"
          [formControlName]="model.C_DATE_FROM"
          [howdenControlError]="dateFromError"
          [min]="minDate"
        />
        <mat-error #dateFromError></mat-error>
        <mat-datepicker-toggle matSuffix [for]="dateFromPicker"></mat-datepicker-toggle>
        <mat-datepicker #dateFromPicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field class="f-basis--2">
        <mat-label i18n="@@app.credit-opinion.search.DateTo">Hasta</mat-label>
        <input matInput
          [matDatepicker]="dateToPicker"
          [formControlName]="model.C_DATE_TO"
          [howdenControlError]="dateToError"
          [min]="minDate"
        />
        <mat-error #dateToError></mat-error>
        <mat-datepicker-toggle matSuffix [for]="dateToPicker"></mat-datepicker-toggle>
        <mat-datepicker #dateToPicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field class="f-basis--2">
        <mat-label i18n="@@app.credit-opinion.search.status">Estado</mat-label>
        <mat-select
          (selectionChange)="onSituationChanged()"
          [formControlName]="model.C_STATUS"
          [howdenControlError]="statusValuesError">
          <mat-option></mat-option>
          @for (item of model.statusValues | howdenArraySort : 'value'; track item.key) {
            <mat-option [value]="item.key">
              {{ item.value }}
            </mat-option>
          }
        </mat-select>
        <mat-error #statusValuesError></mat-error>
      </mat-form-field>

      @if (isClientProfile) {
        <div class="f-basis--1 margin-top-20 margin-left-15">
          @if (canAddOpinion) {
            <button class="new-button" type="button" mat-raised-button  color="primary" (click)="onNew()">
              <span i18n="@@app.credit-opinion.search.new-credit-opinion">Nueva solicitud</span>
            </button>
          }
        </div>
      } @else {
        <div class="f-basis--1 margin-top-20 margin-right-25">
          @if (model.counterMsg) {
            <howden-label-value class="counter-text" value="{{model.counterInfo}}"title="{{model.counterMsg}}">
            </howden-label-value>
          }
        </div>
      }
    </div>
    @if (isUnit4) {
      <div class="flex f-gap--10px margin-top-10 margin-bottom-20">
        <div class="f-basis--4">
          <button type="button"
            mat-raised-button
            color="primary"
            [disabled]="hasNoSelected"
            (click)="onExecuteOperation()"
          >
            <span i18n="@@app.credit-opinion.selection-operation.operation.unit4">
              Descargar UNIT4 Opiniones Seleccionadas
            </span>
          </button>
        </div>
      </div>
    }
  </form>

  <div>
    <howden-table
      #creditOpinionsTable
      class="small-font"
      keyField="id"
      paginable="server"
      sortable="server"
      filterable="server"
      [clickableRow]="true"
      [cols]="model.columns"
      [data]="model.data"
      [currentPage]="model.searchRequest.pageNumber"
      [pageSize]="model.searchRequest.pageSize"
      [totalRows]="model.length"
      [buttons]="model.buttons"
      (rowClicked)="onRowClicked($event)"
      (buttonClicked)="onAction($event)"
      (pageChanged)="onServerSideConfigChanged($event)"
      (sortChanged)="onServerSideConfigChanged($event)"
      [multiselect]="true"
      [hideRowCheckbox]="model.hideCheck"
      [hideToggleSelectionCheckbox]="!isUnit4"
      (selectedRowsChange)="onSelectionChanged($event)"
    >
    </howden-table>
  </div>
</div>
