"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CIF_REGEX = exports.LEGAL_ENTITY_NIF_REGEX = exports.CIF_CONTROL_LETTERS = exports.LEGAL_ENTITY_CONTROL_LETTERS = exports.isValidCif = exports.isValidLegalEntityNif = exports.isValidCifControlCode = exports.isValidLegalEntityNifControlCode = exports.replaceNieLetter = exports.NIE_REGEX = exports.isValidNie = exports.DNI_REGEX = exports.isValidDni = exports.DNI_CONTROL_LETTERS = exports.isValidDniLetter = exports.isValidNaturalPersonNif = exports.isValidNif = void 0;
var nif_1 = require("./nif/nif");
Object.defineProperty(exports, "isValidNif", {
  enumerable: true,
  get: function () {
    return nif_1.isValidNif;
  }
});
var naturalPersonNif_1 = require("./nif/naturalPersonNif/naturalPersonNif");
Object.defineProperty(exports, "isValidNaturalPersonNif", {
  enumerable: true,
  get: function () {
    return naturalPersonNif_1.isValidNaturalPersonNif;
  }
});
var shared_1 = require("./nif/naturalPersonNif/shared");
Object.defineProperty(exports, "isValidDniLetter", {
  enumerable: true,
  get: function () {
    return shared_1.isValidDniLetter;
  }
});
Object.defineProperty(exports, "DNI_CONTROL_LETTERS", {
  enumerable: true,
  get: function () {
    return shared_1.DNI_CONTROL_LETTERS;
  }
});
var dni_1 = require("./nif/naturalPersonNif/dni");
Object.defineProperty(exports, "isValidDni", {
  enumerable: true,
  get: function () {
    return dni_1.isValidDni;
  }
});
Object.defineProperty(exports, "DNI_REGEX", {
  enumerable: true,
  get: function () {
    return dni_1.DNI_REGEX;
  }
});
var nie_1 = require("./nif/naturalPersonNif/nie");
Object.defineProperty(exports, "isValidNie", {
  enumerable: true,
  get: function () {
    return nie_1.isValidNie;
  }
});
Object.defineProperty(exports, "NIE_REGEX", {
  enumerable: true,
  get: function () {
    return nie_1.NIE_REGEX;
  }
});
Object.defineProperty(exports, "replaceNieLetter", {
  enumerable: true,
  get: function () {
    return nie_1.replaceNieLetter;
  }
});
var legalEntityNif_1 = require("./nif/legalEntityNif/legalEntityNif");
Object.defineProperty(exports, "isValidLegalEntityNifControlCode", {
  enumerable: true,
  get: function () {
    return legalEntityNif_1.isValidLegalEntityNifControlCode;
  }
});
Object.defineProperty(exports, "isValidCifControlCode", {
  enumerable: true,
  get: function () {
    return legalEntityNif_1.isValidLegalEntityNifControlCode;
  }
});
Object.defineProperty(exports, "isValidLegalEntityNif", {
  enumerable: true,
  get: function () {
    return legalEntityNif_1.isValidLegalEntityNif;
  }
});
Object.defineProperty(exports, "isValidCif", {
  enumerable: true,
  get: function () {
    return legalEntityNif_1.isValidLegalEntityNif;
  }
});
Object.defineProperty(exports, "LEGAL_ENTITY_CONTROL_LETTERS", {
  enumerable: true,
  get: function () {
    return legalEntityNif_1.LEGAL_ENTITY_CONTROL_LETTERS;
  }
});
Object.defineProperty(exports, "CIF_CONTROL_LETTERS", {
  enumerable: true,
  get: function () {
    return legalEntityNif_1.LEGAL_ENTITY_CONTROL_LETTERS;
  }
});
Object.defineProperty(exports, "LEGAL_ENTITY_NIF_REGEX", {
  enumerable: true,
  get: function () {
    return legalEntityNif_1.LEGAL_ENTITY_NIF_REGEX;
  }
});
Object.defineProperty(exports, "CIF_REGEX", {
  enumerable: true,
  get: function () {
    return legalEntityNif_1.LEGAL_ENTITY_NIF_REGEX;
  }
});