import { Injectable } from '@angular/core';
import { HowdenHttpClientService, IPageOf, IResponse } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { API_PATH_APPS } from 'src/app/core/constants';
import { IUserRole } from 'src/app/core/models/api/master';
import { IUserDataFilter, IUserDataReduced, IUserExtended, IUserRecruitmentPlanInfo } from 'src/app/core/models/api/user-data';
import { UserDataContract } from '.';

@Injectable({
  providedIn: 'root'
})
export class UserDataService implements UserDataContract {
  apiName = API_PATH_APPS.API_INFORMATION_SERVICES;
  basePath = 'api/user-data';

  constructor(private httpClient: HowdenHttpClientService) { }

  getRoles(): Observable<Array<IUserRole>> {
    return this.httpClient.get<Array<IUserRole>>(
      this.apiName,
      `${this.basePath}/roles`
    );
  }

  getAssociations(): Observable<Array<IUserDataReduced>> {
    return this.httpClient.get<Array<IUserDataReduced>>(
      this.apiName,
      `${this.basePath}/associations`
    );
  }

  getClients(onlyMonitorables: boolean): Observable<Array<IUserDataReduced>> {
    return this.httpClient.get<Array<IUserDataReduced>>(
      this.apiName,
      `${this.basePath}/clients/${onlyMonitorables}`
    );
  }

  save(request: FormData): Observable<IResponse<string>> {
    return this.httpClient.post<IResponse<string>>(this.apiName, this.basePath, request);
  }

  search(request: IUserDataFilter): Observable<IPageOf<IUserExtended>> {
    return this.httpClient.post<IPageOf<IUserExtended>>(this.apiName, `${this.basePath}/search`, request);
  }

  get(id: string): Observable<IUserExtended> {
    return this.httpClient.get<IUserExtended>(this.apiName, `${this.basePath}/${id}`);
  }

  getLoggedClientInfo(): Observable<IUserExtended> {
    return this.httpClient.get<IUserExtended>(this.apiName, this.basePath);
  }

  getRecruitmentPlanInfo(id: number): Observable<IUserRecruitmentPlanInfo> {
    return this.httpClient.get<IUserRecruitmentPlanInfo>(this.apiName, `${this.basePath}/user-recruitment-plans/${id}`);
  }

  downloadSecondaryLogo(id: number): Observable<Blob> {
    return this.httpClient.getBlob(this.apiName, `${this.basePath}/download-secondary-logo/${id}`, {
      reportProgress: true,
      responseType: 'blob'
    });
  }

  downloadReportLogo(id: number): Observable<Blob> {
    return this.httpClient.getBlob(this.apiName, `${this.basePath}/download-report-logo/${id}`, {
      reportProgress: true,
      responseType: 'blob'
    });
  }
}
