import { Injectable } from '@angular/core';
import { HowdenHttpClientService, IResponse } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { API_PATH_APPS } from 'src/app/core/constants';
import { ICreditOpinionExercise } from 'src/app/core/models/api/credit-opinion';
import { CreditOpinionExerciseContract } from '.';

@Injectable({
  providedIn: 'root'
})
export class CreditOpinionExerciseService implements CreditOpinionExerciseContract {
  apiName = API_PATH_APPS.API_INFORMATION_SERVICES;
  basePath = 'api/credit-opinion-exercise';

  constructor(
    private httpClient: HowdenHttpClientService
  ) { }

  search(creditOpinionId: string): Observable<Array<ICreditOpinionExercise>> {
    return this.httpClient.get<Array<ICreditOpinionExercise>>(this.apiName, `${this.basePath}/search/${creditOpinionId}`);
  }

  get(id: string): Observable<ICreditOpinionExercise> {
    return this.httpClient.get<ICreditOpinionExercise>(this.apiName, `${this.basePath}/${id}`);
  }

  save(request: ICreditOpinionExercise): Observable<IResponse<boolean>> {
    return this.httpClient.post<IResponse<boolean>>(this.apiName, this.basePath, request);
  }

  delete(id: string): Observable<IResponse<boolean>> {
    return this.httpClient.delete<IResponse<boolean>>(this.apiName, `${this.basePath}/${id}`);
  }
}
