<form [formGroup]="model.form" class="howden-dialog-content">
  <div mat-dialog-title class="title-text" i18n="@@app.credit-opinion.exercise.dialog.title">
    Nueva información financiera
  </div>

  <mat-dialog-content>
    <div class="flex f-gap--10px">
      <mat-form-field class="f-basis--6">
        <mat-label i18n="@@app.credit-opinion.exercise.edit.year">Año</mat-label>
        <input
          matInput
          [formControlName]="model.C_EXERCISE"
          [howdenControlError]="exerciseError"
          type="number"
        />
        <mat-error #exerciseError></mat-error>
      </mat-form-field>
    </div>

    <div class="flex f-gap--10px">
      <howden-decimal
        class="f-basis--6"
        [allowNegative]="true"
        [allowZero]="true"
        [formControlName]="model.C_BILLING"
        i18n-label="@@app.credit-opinion.exercise.edit.billing"
        label="Ventas"
      ></howden-decimal>
      <howden-decimal
        class="f-basis--6"
        [allowNegative]="true"
        [allowZero]="true"
        [formControlName]="model.C_RESULT"
        i18n-label="@@app.credit-opinion.exercise.edit.result"
        label="Resultado"
      ></howden-decimal>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button type="button" mat-raised-button (click)="onCancel()">
      <span i18n="@@app.core.actions.cancel">Cancelar</span>
    </button>
    <button type="button" color="primary" mat-raised-button [disabled]="model.form.invalid" (click)="onAccept()">
      <span i18n="@@app.core.actions.accept">Aceptar</span>
    </button>
  </mat-dialog-actions>
</form>
