import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  ColumnAligment,
  ColumnPipeTypes,
  IHowdenColumnConfig,
  ITableEventData
} from '@howdeniberia/core-front';

import { IDebtorCreditGrantedFilter, IDebtorFilter, IMonitoringUserReduced } from 'src/app/core/models/api/monitoring';
import { IUserDataReduced } from 'src/app/core/models/api/user-data';
import { IDebtorCreditGrantedViewModel } from './debtor-credit-granted.viewmodel';

export class DebtorCreditGrantedSearchViewModel {
  private _filterForm: FormGroup;
  private _searchRequest: IDebtorCreditGrantedFilter;
  private _sortedBy?: string;
  private _sortDirection?: string;
  private _length = 0;

  readonly C_USER_ID = 'userId';
  readonly C_MONITORABLE_CLIENT = 'monitorableClient';
  readonly C_FISCAL_CODE = 'fiscalCode';
  readonly C_BUSINESS_NAME = 'businessName';
  readonly C_OWN_IDENTIFIER = 'ownIdentifier';

  constructor() {
    this._filterForm = new FormGroup({
      [this.C_USER_ID]: new FormControl(null, [Validators.required]),
      [this.C_MONITORABLE_CLIENT]: new FormControl(null, [Validators.required]),
      [this.C_FISCAL_CODE]: new FormControl('', []),
      [this.C_BUSINESS_NAME]: new FormControl('', []),
      [this.C_OWN_IDENTIFIER]: new FormControl('', [])
    });

    this._searchRequest = {
      pageNumber: 0,
      pageSize: 10,
      sortedBy: this._sortedBy,
      sortDirection: this.sortDirection
    } as IDebtorCreditGrantedFilter;
  }

  dataClients: IMonitoringUserReduced[] = [];
  columnsClients: IHowdenColumnConfig[] = [
    {
      fieldName: 'notCreditGrantedPending',
      fieldHeader: '',
      canSort: false,
      pipeToApply: ColumnPipeTypes.CHECK,
      aligment: ColumnAligment.LEFT,
      width: '12%'
    },
    {
      fieldName: 'fiscalCode',
      fieldHeader: $localize`:@@app.debtor.search.client.fiscalCode.column:ID Fiscal`,
      canSort: true,
      width: '25%'
    },
    {
      fieldName: 'businessName',
      fieldHeader: $localize`:@@app.debtor.search.client.businessName.column:Razón social`,
      canSort: true,
      width: '50%'
    }
  ];

  data: IDebtorCreditGrantedViewModel[] = [];
  columns: IHowdenColumnConfig[] = [
    {
      fieldName: 'fiscalCode',
      fieldHeader: $localize`:@@app.debtor-credit-granted.search.fiscalCode.column:ID Fiscal`,
      canSort: false,
      width: '12%'
    },
    {
      fieldName: 'businessName',
      fieldHeader: $localize`:@@app.debtor-credit-granted.search.businessName.column:Razón social`,
      canSort: false,
      width: '25%'
    },
    {
      fieldName: 'ownIdentifier',
      fieldHeader: $localize`:@@app.debtor-credit-granted.search.ownIdentifier.column:Identificador interno`,
      canSort: false,
      width: '20%'
    },
    {
      fieldName: 'monitoringDate',
      fieldHeader: $localize`:@@app.debtor-credit-granted.search.monitoringDate.column:F. monitorización`,
      pipeToApply: ColumnPipeTypes.DATE,
      aligment: ColumnAligment.CENTER,
      canSort: true,
      width: '12%'
    },
    {
      fieldName: 'requestedAmount',
      fieldHeader: $localize`:@@app.debtor-credit-granted.search.requestedAmount.column:Solicitado`,
      canSort: false,
      aligment: ColumnAligment.RIGHT,
      pipeToApply: ColumnPipeTypes.CURRENCY,
      width: '12%'
    },
    {
      fieldName: 'grantedAmount',
      fieldHeader: $localize`:@@app.debtor-credit-granted.search.grantedAmount.column:Concedido`,
      canSort: true,
      aligment: ColumnAligment.RIGHT,
      pipeToApply: ColumnPipeTypes.CURRENCY
    }
  ];


  getControl(controlName: string): AbstractControl<any, any> {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this._filterForm.get(controlName)!;
  }

  get length(): number {
    return this._length;
  }
  set length(length: number) {
    this._length = length;
  }

  get sortedBy(): string | undefined {
    return this._sortedBy;
  }

  get sortDirection(): string | undefined {
    return this._sortDirection;
  }

  get searchRequest(): IDebtorFilter {
    return this._searchRequest;
  }

  get filterForm(): FormGroup {
    return this._filterForm;
  }
  set filterForm(value: FormGroup) {
    this._filterForm = value;
  }

  get userId(): number | null | undefined {
    return this.getControl(this.C_USER_ID)?.value;
  }
  set userId(value: number | null | undefined) {
    this.getControl(this.C_USER_ID)?.setValue(value);
  }

  get monitorableClient(): IUserDataReduced | null | undefined {
    return this.getControl(this.C_MONITORABLE_CLIENT)?.value;
  }
  set monitorableClient(value: IUserDataReduced | null | undefined) {
    this.getControl(this.C_MONITORABLE_CLIENT)?.setValue(value);
  }

  get fiscalCode(): string | null | undefined {
    return this.getControl(this.C_FISCAL_CODE)?.value;
  }
  set fiscalCode(value: string | null | undefined) {
    this.getControl(this.C_FISCAL_CODE)?.setValue(value);
  }

  get businessName(): string | null | undefined {
    return this.getControl(this.C_BUSINESS_NAME)?.value;
  }
  set businessName(value: string | null | undefined) {
    this.getControl(this.C_BUSINESS_NAME)?.setValue(value);
  }

  get ownIdentifier(): string | null | undefined {
    return this.getControl(this.C_OWN_IDENTIFIER)?.value;
  }
  set ownIdentifier(value: string | null | undefined) {
    this.getControl(this.C_OWN_IDENTIFIER)?.setValue(value);
  }

  updateServerSideConfig(event: ITableEventData): void {
    this.searchRequest.pageNumber = event.pageIndex;
    this.searchRequest.pageSize = event.pageSize;
    this.searchRequest.sortedBy = event.fieldSorted;
    this.searchRequest.sortDirection = event.order;

    this.updateServerSideFilters();
  }

  updateServerSideFilters(): void {
    this.searchRequest.userId = this.userId;
    this.searchRequest.fiscalCode = this.fiscalCode;
    this.searchRequest.businessName = this.businessName;
    this.searchRequest.ownIdentifier = this.ownIdentifier;
  }
}
