<form [formGroup]="model.form" class="howden-dialog-content">
  @if (isUnit4) {
    <div mat-dialog-title class="title-text" i18n="@@app.credit-opinion.operation.dialog.unit4.title">
      Descarga UNIT4 opiniones de crédito
    </div>
  }

  <mat-dialog-content>
    <div class="flex f-gap--10px margin-top-20">
      @if (isUnit4) {
        <span i18n="@@app.credit-opinion.operation.dialog.unit4.info">
          Se descargará el UNIT4 de las opiniones de crédito seleccionadas
        </span>
      }
    </div>
    <div class="flex f-gap--10px margin-top-10">
      <span i18n="@@app.credit-opinion.operation.dialog.unit4.info.accept">
        <b>¿Desea continuar?</b>
      </span>
    </div>
    <div class="flex f-gap--10px margin-top-20 margin-left-20 margin-right-20 ">
      <span class="warning-text" i18n="@@app.credit-opinion.operation.dialog.unit4.advise">
        Sólo se aplicará la operación sobre las solicitudes de opinión de crédito marcadas en la página actual
      </span>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button type="button" mat-raised-button (click)="onCancel()">
      <span i18n="@@app.core.actions.cancel">Cancelar</span>
    </button>
    <button type="button" color="primary" mat-raised-button [disabled]="model.form.invalid" (click)="onAccept()">
      <span i18n="@@app.core.actions.accept">Aceptar</span>
    </button>
  </mat-dialog-actions>
</form>
