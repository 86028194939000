import { Injectable } from '@angular/core';
import { HowdenHttpClientService } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { API_PATH_APPS } from 'src/app/core/constants';
import { ICountry } from 'src/app/core/models/api/country';
import { CountryContract } from './country.contract';

@Injectable({
  providedIn: 'root'
})
export class CountryService implements CountryContract {
  apiName = API_PATH_APPS.API_INFORMATION_SERVICES;
  basePath = 'api/country';

  constructor(private httpClient: HowdenHttpClientService) { }

  getCountries(): Observable<Array<ICountry>> {
    return this.httpClient.get<Array<ICountry>>(this.apiName, this.basePath);
  }
}
