<div class="flex f-fd--column">
  <div>
    <howden-page-title></howden-page-title>
  </div>
  <form [formGroup]="model.form">
    <div class="flex f-gap--10px margin-bottom-10">
      <mat-form-field class="f-basis--2">
        <mat-label i18n="@@app.user-request.edit.signatoryFiscalCodeType">Tipo ID Fiscal firmante</mat-label>
        <mat-select
          [formControlName]="model.C_SIGNATORY_FISCAL_CODE_TYPE"
          [howdenControlError]="signFiscalCodeTypeError"
          #roleSelected
        >
          <mat-option></mat-option>
          @for (item of model.fiscalCodeTypes | howdenArraySort : 'value' ; track item.key) {
            <mat-option [value]="item.key">
              {{ item.value }}
            </mat-option>
          }
        </mat-select>
        <mat-error #signFiscalCodeTypeError></mat-error>
      </mat-form-field>

      <mat-form-field class="f-basis--2">
        <mat-label i18n="@@app.user-request.edit.signatoryFiscalCode">ID Fiscal firmante</mat-label>
        <input
          matInput
          [formControlName]="model.C_SIGNATORY_FISCAL_CODE"
          [howdenControlError]="signFiscalCodeError"
          maxlength="20"
        />
        <mat-error #signFiscalCodeError></mat-error>
      </mat-form-field>

      <mat-form-field class="f-basis--3">
        <mat-label i18n="@@app.user-request.edit.signatoryName">Nombre firmante</mat-label>
        <input
          matInput
          [formControlName]="model.C_NAME"
          [howdenControlError]="signatoryNameError"
          maxlength="100"
        />
        <mat-error #signatoryNameError></mat-error>
      </mat-form-field>

      <mat-form-field class="f-basis--5">
        <mat-label i18n="@@app.user-request.edit.signatorySurname">Apellido firmante</mat-label>
        <input
          matInput
          [formControlName]="model.C_SURNAME"
          [howdenControlError]="signatorySurnameError"
          maxlength="250"
        />
        <mat-error #signatorySurnameError></mat-error>
      </mat-form-field>
    </div>

    <div class="flex f-gap--10px margin-bottom-10">
      <mat-form-field class="f-basis--2">
        <mat-label i18n="@@app.user-request.edit.companyFiscalCodeType">Tipo ID Fiscal empresa</mat-label>
        <mat-select
          [formControlName]="model.C_COMPANY_FISCAL_CODE_TYPE"
          [howdenControlError]="companyFiscalCodeTypeError"
          #roleSelected
        >
          <mat-option></mat-option>
          @for (item of model.fiscalCodeTypes | howdenArraySort : 'value'; track item.key) {
            <mat-option [value]="item.key">
              {{ item.value }}
            </mat-option>
          }
        </mat-select>
        <mat-error #companyFiscalCodeTypeError></mat-error>
      </mat-form-field>

      <mat-form-field class="f-basis--2">
        <mat-label i18n="@@app.user-request.edit.companyFiscalCode">ID Fiscal empresa</mat-label>
        <input
          matInput
          [formControlName]="model.C_COMPANY_FISCAL_CODE"
          [howdenControlError]="companyFiscalCodeError"
          maxlength="20"
        />
        <mat-error #companyFiscalCodeError></mat-error>
      </mat-form-field>

      <mat-form-field class="f-basis--3">
        <mat-label i18n="@@app.user-request.edit.companyName">Nombre empresa</mat-label>
        <input
          matInput
          [formControlName]="model.C_COMPANY_NAME"
          [howdenControlError]="companyNameError"
          maxlength="256"
        />
        <mat-error #companyNameError></mat-error>
      </mat-form-field>

      <mat-form-field class="f-basis--5">
        <mat-label i18n="@@app.user-request.edit.companyAddress">Domicilio empresa</mat-label>
        <input
          matInput
          [formControlName]="model.C_COMPANY_ADDRESS"
          [howdenControlError]="companyAddressError"
          maxlength="256"
        />
        <mat-error #companyAddressError></mat-error>
      </mat-form-field>
    </div>

    <div class="flex f-gap--10px margin-bottom-10">
      <mat-form-field class="f-basis--2">
        <mat-label i18n="@@app.user-request.edit.recruitmentPlan">Plan contratado</mat-label>
        <mat-select
          [formControlName]="model.C_RECRUITMENT_PLAN"
          [howdenControlError]="recruitmentPlanError"
        >
          <mat-option></mat-option>
          @for (item of model.recruitmentPlans | howdenArraySort : 'description'; track item.id) {
            <mat-option [value]="item.id">
              {{ item.description }}
            </mat-option>
          }
        </mat-select>
        <mat-error #recruitmentPlanError></mat-error>
      </mat-form-field>

      <mat-form-field class="f-basis--2">
        <mat-label i18n="@@app.user-request.edit.email">Email</mat-label>
        <input
          matInput
          [formControlName]="model.C_EMAIL"
          [howdenControlError]="emailError"
          maxlength="256"
        />
        <mat-error #emailError></mat-error>
      </mat-form-field>

      <mat-form-field class="f-basis--3">
        <mat-label i18n="@@app.user-request.edit.companyActivity">Actividad empresa</mat-label>
        <input
          matInput
          [formControlName]="model.C_COMPANY_ACTIVITY"
          [howdenControlError]="companyActivityError"
          maxlength="256"
        />
        <mat-error #companyActivityError></mat-error>
      </mat-form-field>

      @if (isNew || !model.associationId) {
        <div class="f-basis--5"></div>
      }
      @if (!isNew && model.associationId) {
        <mat-form-field class="f-basis--5">
          <mat-label i18n="@@app.user-request.edit.association">Asociación a la que pertenece</mat-label>
          <input
            matInput
            [formControlName]="model.C_ASSOCIATION_DESC"
          />
          <mat-error #associationError></mat-error>
        </mat-form-field>
      }
    </div>

    @if (!isNew) {
      <div class="flex f-gap--10px margin-bottom-10">
        <mat-form-field class="f-basis--2"  >
          <mat-label i18n="@@app.user-request.edit.signatureStatus">Estado firma</mat-label>
          <mat-select
            [formControlName]="model.C_SIGNATURE_STATUS"
            [howdenControlError]="signatureStatusValuesError"
          >
            <mat-option></mat-option>
            @for (item of model.signatureStatusValues | howdenArraySort : 'value'; track item.key) {
              <mat-option [value]="item.key">
                {{ item.value }}
              </mat-option>
            }
          </mat-select>
          <mat-error #signatureStatusValuesError></mat-error>
        </mat-form-field>

        <mat-form-field class="f-basis--2">
          <mat-label i18n="@@app.user-request.edit.status">Estado</mat-label>
          <mat-select
            [formControlName]="model.C_STATUS"
            [howdenControlError]="statusValuesError"
          >
            <mat-option></mat-option>
            @for (item of model.statusValues | howdenArraySort : 'value'; track item.key) {
              <mat-option [value]="item.key">
                {{ item.value }}
              </mat-option>
            }
          </mat-select>
          <mat-error #statusValuesError></mat-error>
        </mat-form-field>

        <mat-form-field class="f-basis--3">
          <mat-label i18n="@@app.user-request.edit.recruitmentManager">Gestor contratación</mat-label>
          <input matInput [formControlName]="model.C_RECRUITMENT_MANAGER_USER_NAME" />
        </mat-form-field>
        <div class="f-basis--5"></div>
      </div>
    }

    <div class="flex f-jc--center f-gap--10px f-ai--center">
      @if (this.model.form.errors?.['fiscalCodeInvalid']) {
        <mat-error i18n="@@app.validators.fiscal-code-invalid">
          El identificador fiscal no es válido
        </mat-error>
      }
    </div>

    <div class="flex f-gap--10px f-ai--center margin-bottom-20">
      <div class="f-basis--6">
        @if (!isAnonymous) {
          <button mat-raised-button (click)="goToSearch()">
            <span i18n="@@app.actions.cancelar">Atrás</span>
          </button>
        }
      </div>
      <div class="flex f-basis--6 f-jc--end">
        <button
          mat-raised-button
          color="primary"
          [disabled]="model.form.invalid || !canSave"
          (click)="onSave()"
        >
          <span i18n="@@app.actions.save">Guardar</span>
        </button>
      </div>
    </div>
  </form>
</div>
