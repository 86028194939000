@if (!isClientProfile) {
  <div class="flex f-fd--column">
    <form [formGroup]="model.filterForm">
      <div class="flex f-gap--10px margin-top-20">
        <howden-autocomplete
          class="f-basis--4"
          i18n-label="@@app.credit-opinion.search.userId"
          label="Solicitante"
          displayField="description"
          keyField="userId"
          [selectableItems]="model.clients"
          [formControlName]="model.C_USER_ID"
        >
        </howden-autocomplete>
      </div>
      <mat-divider class="flex f-gap--10px margin-bottom-10"></mat-divider>
    </form>
  </div>
}

<div class="flex f-fd--column">
  <div class="flex f-gap--10px ">
    <div  class="f-basis--6">
      <h2 i18n="@@app.debtor-monitoring.actual">Último seguimiento</h2>
    </div>

    <div class="f-basis--6 margin-top-20">
      <div class="flex f-jc--center">
        <span i18n="@@app.debtor-monitoring.actual.evolution">Evolución</span>
      </div>

      <div class="flex f-jc--center">
        <howden-label-value class="debtor-selected" value="{{debtorSelected}}">
        </howden-label-value>
      </div>
    </div>
  </div>

  <div class="flex f-gap--10px ">
    <div class="f-basis--6">
      <howden-table
        #debtorsTable
        class="small-font"
        keyField="debtorId"
        paginable="local"
        sortable="local"
        filterable="server"
        [clickableRow]="true"
        [highlightRow]="true"
        [cols]="model.columnsActualMonitoring"
        [data]="model.dataActualMonitoring"
        [pageSize]="10"
        (rowClicked)="onRowClicked($event)"
        >
      </howden-table>
    </div>

    <div class="f-basis--6">
      @if (infoLoaded) {
        <howden-dashboard-bar-chart
          [data]="creditGrantedByDateToDashboard">
        </howden-dashboard-bar-chart>
      }
    </div>
  </div>

  <mat-divider class="flex f-gap--10px margin-bottom-10"></mat-divider>

  <div class="flex f-gap--10px ">
    <div class="f-basis--6 ">
      <h2 i18n="@@app.debtor-monitoring.history">Seguimiento anual</h2>
    </div>

    <div class="f-basis--6 margin-top-15">
      <div class="flex f-jc--end">
        @if (infoLoaded) {
          <button type="button"
            mat-raised-button
            color="primary"
            (click)="onDownloadHistory()">
            <span i18n="@@app.debtor-monitoring.history.download">Descargar seguimiento</span>
          </button>
        }
      </div>
    </div>
  </div>

  <div class="flex f-gap--10px ">
    <div class="f-basis--12">
      <howden-table
        class="small-font"
        paginable="local"
        sortable="local"
        filterable="server"
        [cols]="model.columnsHistoricalMonitoring"
        [data]="model.dataHistoricalMonitoring"
        [pageSize]="25"
      >
      </howden-table>
    </div>
  </div>
</div>
