<form [formGroup]="model.form" class="howden-dialog-content">
  <div mat-dialog-title class="title-text" i18n="@@app.credit-opinion.edit.reduced.dialog.title">
    Solicitud Opinión Crédito
  </div>

  <mat-dialog-content>
    <div class="flex f-gap--10px">
      <mat-form-field class="f-basis--4">
        <mat-label i18n="@@app.credit-opinion.edit.reduced.dialog.companyAssessFiscalCodeType">Tipo ID Fiscal</mat-label>
        <mat-select
          [formControlName]="model.C_COMPANY_ASSESS_FISCAL_CODE_TYPE"
          [howdenControlError]="companyAssesFiscalCodeTypeError"
        >
          <mat-option></mat-option>
          @for (item of model.fiscalCodeTypeValues | howdenArraySort : 'value'; track item.key) {
            <mat-option [value]="item.key">
              {{ item.value }}
            </mat-option>
          }
        </mat-select>
        <mat-error #companyAssesFiscalCodeTypeError></mat-error>
      </mat-form-field>
      <mat-form-field class="f-basis--4">
        <mat-label i18n="@@app.credit-opinion.edit.reduced.dialog.companyAssessFiscalCode">ID Fiscal</mat-label>
        <input
          matInput
          [formControlName]="model.C_COMPANY_ASSESS_FISCAL_CODE"
          [howdenControlError]="companyAssessFiscalCodeError"
          maxlength="20"
        />
        <mat-error #companyAssessFiscalCodeError></mat-error>
      </mat-form-field>
      @if (needCountry) {
        <howden-autocomplete
          class="f-basis--4"
          i18n-label="@@app.credit-opinion.edit.reduced.dialog.country"
          label="País"
          displayField="name"
          keyField="id"
          [selectableItems]="model.countries"
          [formControlName]="model.C_COMPANY_ASSESS_COUNTRY_ID"
          >
        </howden-autocomplete>
      }
      @if (!needCountry) {
        <div  class="f-basis--4"></div>
      }
    </div>
    @if (this.model.form.errors?.['fiscalCodeInvalid']) {
      <div class="flex f-gap--10px margin-bottom-10 small-letter">
        <mat-error i18n="@@app.validators.fiscal-code-invalid" >
          El identificador fiscal no es válido
        </mat-error>
      </div>
    }
    <div class="flex f-gap--10px">
      <mat-form-field class="f-basis--8">
        <mat-label i18n="@@app.credit-opinion.edit.reduced.dialog.companyAssessName">Razón social</mat-label>
        <input
          matInput
          [formControlName]="model.C_COMPANY_ASSESS_NAME"
          [howdenControlError]="companyAssessNameError"
          maxlength="60"
        />
        <mat-error #companyAssessNameError></mat-error>
      </mat-form-field>
      <howden-decimal
        class="f-basis--4"
        [formControlName]="model.C_REQUESTED_AMOUNT"
        i18n-label="@@app.credit-opinion.edit.reduced.dialog.requestedAmount"
        label="Solicitado"
      ></howden-decimal>
    </div>
    <div class="flex f-gap--10px">
      @if (!isNew) {
        <mat-form-field class="f-basis--4">
          <mat-label i18n="@@app.user-request.edit.reduced.dialog.status">Estado</mat-label>
          <mat-select [formControlName]="model.C_SITUATION" [howdenControlError]="statusValuesError">
            <mat-option></mat-option>
            @for (item of model.creditOpinionStatusValues | howdenArraySort : 'value'; track item.key) {
              <mat-option [value]="item.key">
                {{ item.value }}
              </mat-option>
            }
          </mat-select>
          <mat-error #statusValuesError></mat-error>
        </mat-form-field>
      }

    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button type="button" mat-raised-button (click)="onCancel()">
      <span i18n="@@app.core.actions.cancel">Cancelar</span>
    </button>
    <button type="button" color="primary" mat-raised-button [disabled]="model.form.invalid || !canSave" (click)="onAccept()">
      <span i18n="@@app.core.actions.accept">Aceptar</span>
    </button>
  </mat-dialog-actions>
</form>
