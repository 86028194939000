<div class="flex f-fd--column">
  <div class="flex">
    <div class="f-basis--10">
      <howden-page-title></howden-page-title>
    </div>

    <div class="f-basis--2 f-jc--end margin-top-20">
      @if (canReject) {
        <button
          mat-raised-button
          color="primary"
          (click)="onReject()"
        >
          <span i18n="@@app.credit-opinion.edit.actions.reject">Rechazar</span>
        </button>
      }
      @if (canReactivate) {
        <button
          mat-raised-button
          color="primary"
          (click)="onReactivate()"
          >
          <span i18n="@@app.credit-opinion.edit.actions.reactivate">Reactivar</span>
        </button>
      }
    </div>
  </div>

  <form [formGroup]="model.form">
    <div class="flex f-gap--10px">
      <mat-form-field class="f-basis--3">
        <mat-label i18n="@@app.credit-opinion.edit.userRequestBussinessName">Cliente</mat-label>
        <input matInput [formControlName]="model.C_USER_REQUEST_BUSSINESS_NAME"/>
      </mat-form-field>

      <mat-form-field class="f-basis--3">
        <mat-label i18n="@@app.credit-opinion.edit.userRequestEmail">Email cliente</mat-label>
        <input matInput [formControlName]="model.C_USER_REQUEST_EMAIL"/>
      </mat-form-field>

      @if (model.userRequestContact !== '') {
        <mat-form-field class="f-basis--3">
          <mat-label i18n="@@app.credit-opinion.edit.userRequestContact">Contacto</mat-label>
          <input matInput [formControlName]="model.C_USER_REQUEST_CONTACT"/>
        </mat-form-field>
      }
      @if (model.userRequestAssociation !== '') {
        <mat-form-field class="f-basis--3">
          <mat-label i18n="@@app.credit-opinion.edit.userRequestAssociation">Asociación cliente</mat-label>
          <input matInput [formControlName]="model.C_USER_REQUEST_ASSOCIATION"/>
        </mat-form-field>
      }
    </div>

    @if (model.userResponseEmail) {
      <div class="flex f-gap--10px">
        <mat-form-field class="f-basis--3">
          <mat-label i18n="@@app.credit-opinion.edit.userResponseNameSurname">Usuario respuesta</mat-label>
          <input matInput [formControlName]="model.C_USER_RESPONSE_NAME_SURNAME"/>
        </mat-form-field>

        <mat-form-field class="f-basis--3">
          <mat-label i18n="@@app.credit-opinion.edit.userResponseEmail">Email usuario respuesta</mat-label>
          <input matInput [formControlName]="model.C_USER_RESPONSE_EMAIL"/>
        </mat-form-field>
      </div>
    }

    <mat-divider class="margin-bottom-20"></mat-divider>

    <div class="flex f-gap--10px">
      <mat-form-field class="f-basis--2">
        <mat-label i18n="@@app.credit-opinion.edit.companyAssessFiscalCodeType">Tipo ID Fiscal</mat-label>
        <mat-select
          [formControlName]="model.C_COMPANY_ASSESS_FISCAL_CODE_TYPE"
          [howdenControlError]="companyAssesFiscalCodeTypeError"
        >
          <mat-option></mat-option>
          @for (item of model.fiscalCodeTypeValues | howdenArraySort : 'value'; track item.key) {
            <mat-option [value]="item.key">{{ item.value }}</mat-option>
          }
        </mat-select>
        <mat-error #companyAssesFiscalCodeTypeError></mat-error>
      </mat-form-field>

      <mat-form-field class="f-basis--2">
        <mat-label i18n="@@app.credit-opinion.edit.companyAssessFiscalCode">ID Fiscal</mat-label>
        <input
          matInput
          [formControlName]="model.C_COMPANY_ASSESS_FISCAL_CODE"
          [howdenControlError]="companyAssessFiscalCodeError"
          maxlength="20"
        />
        <mat-error #companyAssessFiscalCodeError></mat-error>
      </mat-form-field>

      <howden-autocomplete
        class="f-basis--2"
        i18n-label="@@app.credit-opinion.edit.country"
        label="País"
        displayField="name"
        keyField="id"
        [selectableItems]="model.countries"
        [formControlName]="model.C_COMPANY_ASSESS_COUNTRY_ID"
      >
      </howden-autocomplete>

      <mat-form-field class="f-basis--6">
        <mat-label i18n="@@app.credit-opinion.edit.companyAssessName">Razón social</mat-label>
        <input matInput
          [formControlName]="model.C_COMPANY_ASSESS_NAME"
          [howdenControlError]="companyAssessNameError"
          maxlength="60"
        />
        <mat-error #companyAssessNameError></mat-error>
      </mat-form-field>
    </div>

    @if (this.model.form.errors?.['fiscalCodeInvalid']) {
      <div class="flex f-gap--10px margin-bottom-10 small-letter">
        <mat-error i18n="@@app.validators.fiscal-code-invalid">
          El identificador fiscal no es válido
        </mat-error>
      </div>
    }

    <div class="flex f-gap--10px">
      <mat-form-field class="f-basis--2">
        <mat-label i18n="@@app.credit-opinion.edit.companyAssessConstYear">Año de constitución</mat-label>
        <input matInput
          [formControlName]="model.C_COMPANY_ASSESS_CONST_YEAR"
          [howdenControlError]="companyAssessConstYearError"
          type="number"
        />
        <mat-error #companyAssessConstYearError></mat-error>
      </mat-form-field>

      <mat-form-field class="f-basis--10">
        <mat-label i18n="@@app.credit-opinion.edit.companyAssessAddress">Domicilio social</mat-label>
        <input matInput
          [formControlName]="model.C_COMPANY_ASSESS_ADDRESS"
          [howdenControlError]="companyAssessAddressError"
          maxlength="100"
        />
        <mat-error #companyAssessAddressError></mat-error>
      </mat-form-field>
    </div>

    <div class="flex f-gap--10px">
      <howden-decimal
        class="f-basis--2 mat-form-field"
        [formControlName]="model.C_REQUESTED_AMOUNT"
        i18n-label="@@app.credit-opinion.edit.requestedAmount"
        label="Solicitado"
      ></howden-decimal>

      <mat-form-field class="f-basis--2">
        <mat-label i18n="@@app.credit-opinion.edit.lastGrantedAmount">Última cantidad concedida</mat-label>
        <input matInput [formControlName]="model.C_LAST_GRANTED_AMOUNT"/>
      </mat-form-field>

      <howden-decimal
        class="f-basis--2 mat-form-field"
        [formControlName]="model.C_CREDIT_LIMIT"
        i18n-label="@@app.credit-opinion.edit.creditLimit"
        label="Crédito máximo"
      ></howden-decimal>

      <howden-decimal
        class="f-basis--2 mat-form-field"
        [formControlName]="model.C_GRANTED_AMOUNT"
        i18n-label="@@app.credit-opinion.edit.grantedAmount"
        label="Concedido"
      ></howden-decimal>

      <mat-form-field class="f-basis--2">
        <mat-label i18n="@@app.credit-opinion.edit.grade">Grade</mat-label>
        <mat-select [formControlName]="model.C_GRADE" [howdenControlError]="gradeError">
          <mat-option></mat-option>
          @for (item of model.gradeValues | howdenArraySort : 'key'; track item.key) {
            <mat-option [value]="item.key">
              {{ item.key === 0 ? 'NA' : item.key}}
            </mat-option>
          }
        </mat-select>
        <mat-error #gradeError></mat-error>
      </mat-form-field>

      <mat-form-field class="f-basis--2" >
        <mat-label i18n="@@app.credit-opinion.edit.status">Estado</mat-label>
        <mat-select [formControlName]="model.C_SITUATION" [howdenControlError]="statusError">
          <mat-option></mat-option>
          @for (item of model.statusValues | howdenArraySort : 'value'; track item.key) {
            <mat-option [value]="item.key">
              {{ item.value }}
            </mat-option>
          }
        </mat-select>
        <mat-error #statusError></mat-error>
      </mat-form-field>
    </div>

    <div class="flex f-jc--center f-gap--10px f-ai--center small-letter">
      @if (this.model.form.errors?.['grantedRequestedInvalid']) {
        <mat-error i18n="@@app.validators.granted-requested-invalid">
          La cantidad concedida no puede superar la cantidad solicitada
        </mat-error>
      }
      @if (this.model.form.errors?.['grantedLimitInvalid']) {
        <mat-error i18n="@@app.validators.granted-limit-invalid">
          La cantidad concedida no puede superar el crédito máximo
        </mat-error>
      }
    </div>

    <div class="flex f-gap--10px">
      <div class="f-basis--6">
        <div class="flex f-gap--10px margin-top-20">
          <mat-slide-toggle
            class="f-basis--4"
            color="primary"
            [formControlName]="model.C_ADMINISTRATIVE_CLAIMS"
            i18n="@@app.credit-opinion.edit.administrativeClaims"
          >
            Reclamaciones administrativas
          </mat-slide-toggle>

          <mat-slide-toggle
            class="f-basis--4"
            color="primary"
            [formControlName]="model.C_JUDICIAL_INCIDENTS"
            i18n="@@app.credit-opinion.edit.judicialIncidents"
          >
              Indicendias judiciales
          </mat-slide-toggle>
          <div class="f-basis--4"></div>
        </div>

        <div class="flex f-gap--10px margin-top-30">
          <mat-slide-toggle
            class="f-basis--4"
            color="primary"
            [formControlName]="model.C_CONCURSAL_PROCEDURES"
            i18n="@@app.credit-opinion.edit.judicialIncidents"
          >
              Procedimientos concursales
          </mat-slide-toggle>

          <mat-slide-toggle
            class="f-basis--4"
            color="primary"
            [formControlName]="model.C_UNPAID_BILLS"
            i18n="@@app.credit-opinion.edit.unpaidBills"
          >
              Impagos
          </mat-slide-toggle>

          <mat-slide-toggle
            class="f-basis--4"
            color="primary"
            [formControlName]="model.C_RAI"
            i18n="@@app.credit-opinion.edit.rai"
          >
            RAI
          </mat-slide-toggle>
        </div>

        <div class="flex f-gap--10px margin-top-30">
          <mat-form-field class="f-basis--12">
            <mat-label i18n="@@app.credit-opinion.edit.evaluationActivity">Evaluación de la actividad</mat-label>
            <mat-select [formControlName]="model.C_EVALUATION_ACTIVITY" [howdenControlError]="evaluationActivityError">
              <mat-option></mat-option>
              @for (item of model.evaluationActivityValues | howdenArraySort : 'order'; track item.id) {
                <mat-option [value]="item.id">
                  {{ item.description }}
                </mat-option>
              }
            </mat-select>
            <mat-error #evaluationActivityError></mat-error>
          </mat-form-field>
        </div>

        <div class="flex f-gap--10px">
          <mat-form-field class="f-basis--12">
            <mat-label i18n="@@app.credit-opinion.edit.annualAccounts">Cuentas anuales</mat-label>
            <mat-select [formControlName]="model.C_ANNUAL_ACCOUNTS" [howdenControlError]="annualAccountsError">
              <mat-option></mat-option>
              @for (item of model.annualAccountsValues | howdenArraySort : 'order'; track item.id) {
                <mat-option [value]="item.id">
                  {{ item.description }}
                </mat-option>
              }
            </mat-select>
            <mat-error #annualAccountsError></mat-error>
          </mat-form-field>
        </div>

        <div class="flex f-gap--10px">
          <mat-form-field class="f-basis--12">
            <mat-label i18n="@@app.credit-opinion.edit.longTermDebtEvaluation">Evaluación de las deudas a largo plazo</mat-label>
            <mat-select [formControlName]="model.C_LONG_TERM_DEBT_EVALUATION"
              [howdenControlError]="longTermDebtEvaluationError">
              <mat-option></mat-option>
              @for (item of model.longTermDebtEvaluationValues | howdenArraySort : 'order'; track item.id) {
                <mat-option [value]="item.id">
                  {{ item.description }}
                </mat-option>
              }
            </mat-select>
            <mat-error #longTermDebtEvaluationError></mat-error>
          </mat-form-field>
        </div>

        <div class="flex f-gap--10px">
          <mat-form-field class="f-basis--12">
            <mat-label i18n="@@app.credit-opinion.edit.shortTermDebtEvaluation">Evaluación de las deudas a corto plazo</mat-label>
            <mat-select [formControlName]="model.C_SHORT_TERM_DEBT_EVALUATION"
              [howdenControlError]="shortTermDebtEvaluationError">
              <mat-option></mat-option>
              @for (item of model.shortTermDebtEvaluationValues | howdenArraySort : 'order'; track item.id) {
                <mat-option [value]="item.id">
                  {{ item.description }}
                </mat-option>
              }
            </mat-select>
            <mat-error #shortTermDebtEvaluationError></mat-error>
          </mat-form-field>
        </div>

        <div class="flex f-gap--10px">
          <mat-form-field class="f-basis--12" >
            <mat-label i18n="@@app.credit-opinion.edit.observations">Observaciones</mat-label>
            <input matInput
              [formControlName]="model.C_OBSERVATIONS"
              [howdenControlError]="observationsError"
              maxlength="2000"
            />
            <mat-error #observationsError></mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="f-basis--6">
        <div class="flex f-gap--10px">
          <div class="f-basis--12">
            <howden-credit-opinion-exercises [creditOpinionId]="this.creditOpinionId" #exercises></howden-credit-opinion-exercises>
          </div>
        </div>

        <div class="flex f-gap--10px margin-top-30">
          <div class="f-basis--12">
            <howden-credit-opinion-attachments [creditOpinionId]="this.creditOpinionId"></howden-credit-opinion-attachments>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div class="flex f-gap--10px f-ai--center margin-bottom-20">
    <div class="f-basis--6">
      <button mat-raised-button (click)="goToSearch()">
        <span i18n="@@app.actions.cancelar">Atrás</span>
      </button>
    </div>
    <div class="flex f-basis--6 f-jc--end">
      <button
        mat-raised-button
        color="primary"
        [disabled]="model.form.invalid || !canUpdate || !hasExerciseInfo"
        (click)="onSave()"
      >
        <span i18n="@@app.actions.save">Guardar</span>
      </button>
    </div>
  </div>
</div>
