<form [formGroup]="model.form" class="howden-dialog-content">
  <div mat-dialog-title class="title-text" i18n="@@app.recruitment-plan.edit.dialog.title">Plan de Contratación</div>

  <mat-dialog-content>
    <div class="flex f-gap--10px">
      <mat-form-field class="f-basis--6">
        <mat-label i18n="@@app.recruitment-plan.edit.dialog.description">Descripción</mat-label>
        <input
          matInput
          [formControlName]="model.C_DESCRIPTION"
          [howdenControlError]="descriptionError"
          maxlength="50"
        />
        <mat-error #descriptionError></mat-error>
      </mat-form-field>

      <mat-form-field class="f-basis--3">
        <mat-label i18n="@@app.recruitment-plan.edit.dialog.startDate">Fecha Inicio</mat-label>
        <input matInput
          [matDatepicker]="startDatePicker"
          [formControlName]="model.C_START_DATE"
          [howdenControlError]="startDateError"
          [min]="minDate"
        />
        <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker></mat-datepicker>
        <mat-error #startDateError></mat-error>
      </mat-form-field>

      <mat-form-field class="f-basis--3">
        <mat-label i18n="@@app.recruitment-plan.edit.dialog.endDate">Fecha Fin</mat-label>
        <input matInput
          [matDatepicker]="endDatePicker"
          [formControlName]="model.C_END_DATE"
          [howdenControlError]="endDateError"
          [min]="minDate"
        />
        <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker></mat-datepicker>
        <mat-error #endDateError></mat-error>
      </mat-form-field>
    </div>

    <div class="flex f-gap--10px margin-bottom-10">
      <div class="f-basis--6"></div>
      <div class="f-basis--6 small-letter">
        @if (this.model.form.errors?.['dateRange']) {
          <mat-error i18n="@@app.recruitment-plan.edit.dateRange.error">
            La fecha de inicio no puede ser mayor que la fecha fin.
          </mat-error>
        }
        @if (this.model.form.errors?.['startDateInitMonth']) {
          <mat-error i18n="@@recruitment-plan.edit.startDate.initMonth.error">
            La fecha de inicio debe coincidir con inicio de mes.
          </mat-error>
        }
        @if (this.model.form.errors?.['startDateFuture']) {
          <mat-error i18n="@@recruitment-plan.edit.startDate.future.error">
            La fecha de inicio no puede ser anterior a hoy.
          </mat-error>
        }
        @if (this.model.form.errors?.['endDateEndMonth']) {
          <mat-error i18n="@@recruitment-plan.edit.endDate.endMonth.error">
            La fecha de finalización debe coincidir con fin de mes.
          </mat-error>
        }
        @if (this.model.form.errors?.['endDateFuture']) {
          <mat-error i18n="@@recruitment-plan.edit.endDate.endMonth.error">
            La fecha de finalización no puede ser anterior a hoy.
          </mat-error>
        }
      </div>
    </div>

    <div class="flex f-gap--10px">
      <howden-decimal
        class="f-basis--3"
        [formControlName]="model.C_MONTHLY_PRICE"
        i18n-label="@@app.recruitment-plan.edit.dialog.monthlyPrice"
        label="Cuota mensual"
      ></howden-decimal>

      <mat-slide-toggle
        color="primary"
        class="f-basis--3 margin-top-20"
        [formControlName]="model.C_ONLY_CREDIT_OPINION"
        (change)="checkDiferentOptions()"
        i18n="@@app.recruitment-plan.edit.dialog.onlyCreditOpinion">
        Sólo opiniones
      </mat-slide-toggle>

      <mat-form-field class="f-basis--3">
        <mat-label i18n="@@app.recruitment-plan.edit.dialog.creditOpinionLimit">Límite opiniones</mat-label>
        <input matInput
          [formControlName]="model.C_CREDIT_OPINION_LIMIT"
          [howdenControlError]="creditOpinionLimitError"
          type="number"
        />
        <mat-error #creditOpinionLimitError></mat-error>
      </mat-form-field>

      <howden-decimal
        class="f-basis--3"
        [formControlName]="model.C_CREDIT_OPINION_PRICE"
        i18n-label="@@app.recruitment-plan.edit.dialog.creditOpinionPrice"
        label="Precio opinión"
      ></howden-decimal>
    </div>

    <div class="flex f-gap--10px">
      <div class="f-basis--3"></div>

      <mat-slide-toggle
        color="primary"
        class="f-basis--3 margin-top-20"
        [formControlName]="model.C_ONLY_MONITORING"
        (change)="checkDiferentOptions()"
        i18n="@@app.recruitment-plan.edit.dialog.onlyMonitoring">
        Sólo monitorizaciones
      </mat-slide-toggle>

      <mat-form-field class="f-basis--3">
        <mat-label i18n="@@app.recruitment-plan.edit.dialog.monitoringLimit">Límite monitorizaciones</mat-label>
        <input matInput
          [formControlName]="model.C_MONITORING_LIMIT"
          [howdenControlError]="monitoringLimitError"
          type="number"
        />
        <mat-error #monitoringLimitError></mat-error>
      </mat-form-field>
      <div class="f-basis--3"></div>
    </div>

    <div class="flex f-gap--10px">
      <mat-form-field class="f-basis--6" >
        <mat-label i18n="@@app.recruitment-plan.edit.association">Asociación</mat-label>
        <mat-select
          [formControlName]="model.C_ASSOCIATION"
          [howdenControlError]="associationError"
        >
          <mat-option></mat-option>
          @for (item of model.associations | howdenArraySort : 'Description'; track item.userId) {
            <mat-option [value]="item.userId">
              {{ item.description }}
            </mat-option>
          }
        </mat-select>
        <mat-error #associationError></mat-error>
      </mat-form-field>

      <div class="f-basis--6">
        @if (!isNew && !model.contractName) {
          <mat-icon color="warn"
            class="ico-not-download"
            [title]="'No hay plantilla de contrato cargada'" i18n-title="@@app.recruitment-plan.edit.dialog.contract-template.not-loaded">
            broken_image
          </mat-icon>
        }
        @if (!isNew && model.contractName) {
          <mat-icon color="primary"
            class="ico-download"
            (click)="downloadContractTemplate()"
            [title]="'Descargar Contrato Anterior'" i18n-title="@@app.recruitment-plan.edit.dialog.contract-template.loaded">
            image
          </mat-icon>
        }
        <mat-label i18n="@@recruitment-plan.edit.dialog.contractName">Plantilla Contrato (.pdf)</mat-label>
        <howden-file-upload
          #contractTemplate
          (filesSelected)="onContractTemplateSelected()"
          [class]="classContractFile"
        >
        </howden-file-upload>
      </div>
    </div>

    <div class="flex f-gap--10px">
      <mat-form-field class="f-basis--9">
        <mat-label i18n="@@app.recruitment-plan.edit.dialog.observations">Observaciones</mat-label>
        <input
          matInput
          [formControlName]="model.C_OBSERVATIONS"
          [howdenControlError]="observationsError"
          maxlength="2000"
        />
        <mat-error #observationsError></mat-error>
      </mat-form-field>

      <mat-form-field class="f-basis--3">
        <mat-label i18n="@@app.user.edit.status">Estado</mat-label>
        <mat-select
          [formControlName]="model.C_STATUS"
          [howdenControlError]="statusError"
          >
          <mat-option></mat-option>
          @for (item of model.statusValues | howdenArraySort : 'value'; track item.key) {
            <mat-option [value]="item.key">
              {{ item.value }}
            </mat-option>
          }
        </mat-select>
        <mat-error #statusError></mat-error>
      </mat-form-field>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button type="button" mat-raised-button (click)="onCancel()">
      <span i18n="@@app.core.actions.cancel">Cancelar</span>
    </button>
    <button type="button" color="primary" color="primary" mat-raised-button [disabled]="model.form.invalid" (click)="onAccept()">
      <span i18n="@@app.core.actions.accept">Aceptar</span>
    </button>
  </mat-dialog-actions>
</form>
