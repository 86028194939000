import { Injectable } from '@angular/core';
import { HowdenHttpClientService, IPageOf, IResponse } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { API_PATH_APPS } from 'src/app/core/constants';
import {
  ICreditOpinion,
  ICreditOpinionCounter,
  ICreditOpinionFilter,
  ICreditOpinionReduced,
  ICreditOpinionSelection
} from 'src/app/core/models/api/credit-opinion';

import { CreditOpinionContract } from './credit-opinion.contract';

@Injectable({
  providedIn: 'root'
})
export class CreditOpinionService implements CreditOpinionContract {
  apiName = API_PATH_APPS.API_INFORMATION_SERVICES;
  basePath = 'api/credit-opinion';

  constructor(
    private httpClient: HowdenHttpClientService
  ) { }

  search(request: ICreditOpinionFilter): Observable<IPageOf<ICreditOpinion>> {
    return this.httpClient.post<IPageOf<ICreditOpinion>>(this.apiName, `${this.basePath}/search`, request);
  }

  getReduced(id: string): Observable<ICreditOpinionReduced> {
    return this.httpClient.get<ICreditOpinionReduced>(this.apiName, `${this.basePath}/reduced/${id}`);
  }

  get(id: string): Observable<ICreditOpinion> {
    return this.httpClient.get<ICreditOpinion>(this.apiName, `${this.basePath}/${id}`);
  }

  getCounter(): Observable<ICreditOpinionCounter> {
    return this.httpClient.get<ICreditOpinionCounter>(this.apiName, `${this.basePath}/counter`);
  }

  saveReduced(request: ICreditOpinionReduced): Observable<IResponse<boolean>> {
    return this.httpClient.post<IResponse<boolean>>(this.apiName, `${this.basePath}/save-reduced`, request);
  }

  save(request: ICreditOpinion): Observable<IResponse<boolean>> {
    return this.httpClient.post<IResponse<boolean>>(this.apiName, this.basePath, request);
  }

  delete(id: string): Observable<IResponse<boolean>> {
    return this.httpClient.delete<IResponse<boolean>>(this.apiName, `${this.basePath}/${id}`);
  }

  reject(creditOpinionId: string): Observable<ICreditOpinion> {
    return this.httpClient.get<ICreditOpinion>(this.apiName, `${this.basePath}/reject/${creditOpinionId}`);
  }

  reactivate(creditOpinionId: string): Observable<ICreditOpinion> {
    return this.httpClient.get<ICreditOpinion>(this.apiName, `${this.basePath}/reactivate/${creditOpinionId}`);
  }

  downloadPdf(id: string): Observable<Blob> {
    return this.httpClient.getBlob(this.apiName, `${this.basePath}/download-pdf/${id}`, {
      reportProgress: true,
      responseType: 'blob'
    });
  }

  executeOperationBlob(request: ICreditOpinionSelection): Observable<Blob> {
    return this.httpClient.postRespBlob(this.apiName, `${this.basePath}/execute-operation-blob`, request, {
      reportProgress: true,
      responseType: 'blob'
    });
  }
}
